import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
// import signInSchema from "../schema/signInSchema";
import { PostReq } from "lib/requests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import { Box, Card, CircularProgress, Grid, Stack } from "@mui/material";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import Loader from "components/Loader";
import { PatchReq } from "lib/requests";
import { addValidation, editValidation } from "../schema/userFormSchema";

function UserForm({ mode }) {
  const { token } = useSelector((state) => state.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [loader, setLoader] = useState(false);

  const { userId } = useParams();

  const initialState = {
    email: "",
    phoneNumber: "",
    name: "",
    password: "",
    active: true,
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (mode.toLowerCase() === "edit") {
      (async () => {
        setLoader(true);
        try {
          const response = await GetReq(`user/${userId}`, true, token);
          setCurrentUser(response.data.data);
          setLoader(false);
        } catch (error) {
          errorHandler(error);
          setLoader(false);
        }
      })();
    }
  }, [token, mode, userId]);

  const submitHandler = async (values) => {
    if (mode.toLowerCase() === "add") {
      try {
        setLoader(true);
        const response = await PostReq("add-user", values, true, "json", token);
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/users", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    } else if (mode.toLowerCase() === "edit") {
      try {
        setLoader(true);
        const finalValues = { ...values, archived: !values.active };
        delete finalValues.active;
        delete finalValues.password;
        const response = await PatchReq(
          `update-user/${userId}`,
          finalValues,
          true,
          "json",
          token
        );
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/users", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    }
  };

  if (currentUser) {
    initialState.email = currentUser.email;
    initialState.phoneNumber = currentUser.phoneNumber;
    initialState.name = currentUser.name;
    initialState.active = !currentUser.archived;
  }

  console.log({ initialState });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Grid
          container
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  {mode === "add" ? "Add User" : "Edit User"}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  validationSchema={
                    mode === "add" ? addValidation : editValidation
                  }
                  onSubmit={(values) => submitHandler(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftBox>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Name
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="name"
                            id="name"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue("name", event.target.value.trim());
                            }}
                            type="text"
                            placeholder="Name"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.name && touched.name && errors.name}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Phone Number
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="phoneNumber"
                            id="phoneNumber"
                            value={values.phoneNumber}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue(
                                "phoneNumber",
                                event.target.value.trim()
                              );
                            }}
                            disabled={mode === "edit"}
                            type="tel"
                            placeholder="Phone Number"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Email
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="email"
                            id="email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue("email", event.target.value.trim());
                            }}
                            type="email"
                            placeholder="Email"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.email && touched.email && errors.email}
                          </SoftTypography>
                        </SoftBox>

                        {mode.toLowerCase() === "add" && (
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Password
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              name="password"
                              id="password"
                              value={values.password}
                              onBlur={handleBlur}
                              onChange={(event) => {
                                setFieldValue(
                                  "password",
                                  event.target.value.trim()
                                );
                              }}
                              type="password"
                              placeholder="Password"
                            />
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="error"
                            >
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </SoftTypography>
                          </SoftBox>
                        )}
                        {mode.toLowerCase() === "edit" && (
                          <SoftBox mt={3} mb={2} lineHeight={1}>
                            <SoftTypography variant="h6">Status</SoftTypography>

                            <Switch
                              id="active"
                              name="active"
                              checked={values.active}
                              onChange={handleChange}
                              value={values.active}
                            />
                          </SoftBox>
                        )}

                        <SoftBox mt={4} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {mode === "add" ? "Add User" : "Edit User"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default UserForm;
