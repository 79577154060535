import * as Yup from "yup";

export default Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must contain at least length 6")
    .required("Password is required."),
  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
