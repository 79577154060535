import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GetReq } from "lib/requests";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "examples/Tables/Table";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { DeleteReq } from "lib/requests";
import { toast } from "react-hot-toast";
import { errorHandler } from "hooks/methods";
import CustomDialog from "components/Dialog";
import Loader from "components/Loader";
import SoftBadge from "components/SoftBadge";
import CustomCard from "components/CustomCard";
import SoftInput from "components/SoftInput";
import SearchIcon from "@mui/icons-material/Search";
const Users = () => {
  const { token } = useSelector((state) => state.user);

  const [userList, setUserList] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortOn, setSortOn] = useState("createdAt");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(
          `user-list?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
          true,
          token
        );
        setUserList(response.data.users);
        setUserCount(response.data.userCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token, page]);

  // useEffect(() => {
  //   (async () => {})();
  // }, [sortOn, search]);

  const handleChange = async (event, value) => {
    setPage(value);
    try {
      const response = await GetReq(
        `user-list?page=${value}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setUserList(response.data.users);
      setUserCount(response.data.userCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);

    try {
      const response = await GetReq(
        `user-list?page=${page}&limit=${10}&sort=${sortOn}&search=${
          event.target.value
        }`,
        true,
        token
      );
      setUserList(response.data.users);
      setUserCount(response.data.userCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteHandler = async (userId) => {
    setCurrentUser(userId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmationHandler = async () => {
    setOpen(false);
    setLoader(true);
    try {
      const response = await DeleteReq(
        `delete-user/${currentUser}`,
        true,
        token
      );
      toast.remove();
      setUserList((prevState) =>
        prevState.filter((user) => user._id !== currentUser)
      );
      toast.success(response.data.message);
      const userResponse = await GetReq(
        `user-list?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setUserList(userResponse.data.users);
      setUserCount(userResponse.data.userCount);
      setLoader(false);
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  function User({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const columns = [
    { name: "user", align: "left", sort: true },
    { name: "phone", align: "center", sort: true },
    //   { name: "function", align: "left" },
    //   { name: "status", align: "center" },
    { name: "account created", align: "center", sort: true },
    { name: "status", align: "center", sort: true },
    { name: "action", align: "center", sort: false },
  ];

  const rows = [];

  const handleSort = async (name) => {
    let key = "cratedAt";
    if (name === "user") {
      if (sortOn === "name" && name[0] !== "-") {
        setSortOn(() => "-name");
        key = "-name";
      } else {
        setSortOn(() => "name");
        key = "name";
      }
    } else if (name === "account created" && name[0] !== "-") {
      if (sortOn === "createdAt") {
        setSortOn(() => "-createdAt");
        key = "-createdAt";
      } else {
        setSortOn(() => "createdAt");
        key = "createdAt";
      }
    } else if (name === "status" && name[0] !== "-") {
      if (sortOn === "archived") {
        setSortOn(() => "-archived");
        key = "-archived";
      } else {
        setSortOn(() => "archived");
        key = "archived";
      }
    } else if (name === "phone" && name[0] !== "-") {
      if (sortOn === "phoneNumber") {
        setSortOn(() => "-phoneNumber");
        key = "-phoneNumber";
      } else {
        setSortOn(() => "phoneNumber");
        key = "phoneNumber";
      }
    }

    try {
      const response = await GetReq(
        `user-list?page=${page}&limit=${10}&sort=${key}&search=${search}`,
        true,
        token
      );
      setUserList(response.data.users);
      setUserCount(response.data.userCount);
    } catch (error) {
      errorHandler(error);
    }
    // await fetchData();
  };

  const pageCount = Math.ceil(userCount / 10);

  for (const row of userList) {
    rows.push({
      user: <User image={row.image} name={row.name} email={row.email} />,
      "account created": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {new Date(row.createdAt).getDate()} /
          {new Date(row.createdAt).getMonth()} /
          {new Date(row.createdAt).getFullYear()}
        </SoftTypography>
      ),
      phone: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.phoneNumber}
        </SoftTypography>
      ),
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent={row.archived === false ? "active" : "Inactive"}
          color={row.archived === false ? "success" : "error"}
          size="xs"
          container
        />
      ),
      action: (
        <>
          <Link to={`/edit-user/${row._id}`}>
            <SoftButton
              color="info"
              variant="gradient"
              // onClick={handleWhiteSidenav}
              // disabled={disabled}
              // sx={sidenavTypeButtonsStyles}
              sx={{ marginRight: "10px" }}
            >
              Edit
            </SoftButton>
          </Link>
          <SoftButton
            color="error"
            variant="gradient"
            sx={{ marginLeft: "10px" }}
            onClick={() => deleteHandler(row._id)}
            // disabled={disabled}
            // sx={sidenavTypeButtonsStyles}
          >
            Delete
          </SoftButton>
        </>
      ),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loader && <Loader />}
      {!loader && (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                {/* <Stack direction="row" alignItems="center"> */}
                <SoftInput
                  name="search"
                  id="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search User"
                />
                {/* <button
                    style={{
                      padding: "5px 30px",
                      cursor: "pointer",
                      background: "white",
                      border: "0.0625rem solid #d2d6da",
                      borderRadius: "5px",
                    }}
                    type="button"
                    onClick={handleSearch}
                  >
                    <Icon
                      fontSize="30px"
                      sx={{ color: "#d2d6da !important" }}
                      color="#d2d6da !important"
                    >
                      search
                    </Icon>
                  </button> */}
                {/* </Stack> */}
              </Grid>
              <SoftButton
                color="info"
                variant="gradient"
                onClick={() => navigate("/add-user")}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
              >
                Add User
              </SoftButton>
            </Grid>
          </SoftBox>

          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Users table</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {!loader && (
                <Table
                  columns={columns}
                  rows={rows}
                  docCount={userCount}
                  handleSort={handleSort}
                  message="No user exists."
                />
              )}
              <CustomDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                confirmationHandler={confirmationHandler}
                title="Are you sure?"
                content="You want to delete this user?"
                loader={loader}
              />
            </SoftBox>
          </Card>

          {pageCount > 1 && (
            <Card
              sx={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Card>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Users;
