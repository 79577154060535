import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Card, Grid } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

const UserChart = ({ userChartData, title }) => {
  const [labels, setLabels] = useState(null);
  const [userData, setUserData] = useState(null);
  const [chartKey, setChartKey] = useState("current week");

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // const data = ;

  const handelDataSet = (key) => {
    setChartKey(key);
  };

  useEffect(() => {
    if (chartKey === "current week") {
      const tempLabels = [];
      const tempData = [];
      for (const currentWeekData of userChartData["currentWeek"]) {
        tempLabels.push(currentWeekData["day"]);
        tempData.push(currentWeekData["count"]);
      }
      setLabels(tempLabels);
      setUserData(tempData);
    } else if (chartKey === "last week") {
      const tempLabels = [];
      const tempData = [];
      for (const lastWeekData of userChartData["lastWeek"]) {
        tempLabels.push(lastWeekData["day"]);
        tempData.push(lastWeekData["count"]);
      }
      setLabels(tempLabels);
      setUserData(tempData);
    } else if (chartKey === "month") {
      const tempLabels = [];
      const tempData = [];
      for (const monthData of userChartData["month"]) {
        tempLabels.push(monthData["month"]);
        tempData.push(monthData["count"]);
      }
      setLabels(tempLabels);
      setUserData(tempData);
    }
  }, [chartKey, userChartData]);

  console.log({ labels });
  console.log({ userData });

  return (
    <Card sx={{ padding: "50px" }}>
      <SoftTypography
        sx={{ textAlign: "center", marginBottom: "20px" }}
        textTransform="uppercase"
      >
        {title}'s chart for {chartKey}
      </SoftTypography>
      <Grid container justifyContent="end">
        <Grid item xs={12} md={6} lg={3} marginX="10px">
          <SoftButton
            size="small"
            color="info"
            variant={chartKey === "month" ? "contained" : "outlined"}
            sx={{ width: "100%" }}
            onClick={() => handelDataSet("month")}
          >
            Month
          </SoftButton>
        </Grid>
        <Grid item xs={12} md={6} lg={3} marginX="10px">
          <SoftButton
            size="small"
            color="info"
            variant={chartKey === "last week" ? "contained" : "outlined"}
            sx={{ width: "100%" }}
            onClick={() => handelDataSet("last week")}
          >
            Last Week
          </SoftButton>
        </Grid>
        <Grid item xs={12} md={6} lg={3} marginX="10px">
          <SoftButton
            size="small"
            color="info"
            variant={chartKey === "current week" ? "contained" : "outlined"}
            sx={{ width: "100%" }}
            onClick={() => handelDataSet("current week")}
          >
            Current Week
          </SoftButton>
        </Grid>
      </Grid>

      {labels && (
        <Line
          options={options}
          data={{
            labels: labels,
            datasets: [
              {
                label: title,
                data: userData,
                borderColor: "#17c1e8",
                backgroundColor: "#9BD0F5",
              },
            ],
          }}
        />
      )}
    </Card>
  );
};

export default UserChart;
