import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GetReq } from "lib/requests";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "examples/Tables/Table";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { DeleteReq } from "lib/requests";
import { toast } from "react-hot-toast";
import { errorHandler } from "hooks/methods";
import CustomDialog from "components/Dialog";
import Loader from "components/Loader";
import SoftBadge from "components/SoftBadge";
import CustomCard from "components/CustomCard";
import SoftInput from "components/SoftInput";
import SearchIcon from "@mui/icons-material/Search";
const Users = () => {
  const { token } = useSelector((state) => state.user);

  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [open, setOpen] = useState(false);
  // const [sortOn, setSortOn] = useState("createdAt");
  // const [search, setSearch] = useState("");
  // const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  const [templateCount, setTemplateCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(`get-email-templates`, true, token);
        setTemplateList(response.data.templates);
        setTemplateCount(response.data.templateCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token]);

  // useEffect(() => {
  //   (async () => {})();
  // }, [sortOn, search]);

  //   const handleChange = async (event, value) => {
  //     setPage(value);
  //     try {
  //       const response = await GetReq(
  //         `user-list?page=${value}&limit=${10}&sort=${sortOn}&search=${search}`,
  //         true,
  //         token
  //       );
  //       setUserList(response.data.users);
  //       setUserCount(response.data.userCount);
  //     } catch (error) {
  //       errorHandler(error);
  //     }
  //   };

  const deleteHandler = async (templateId) => {
    setCurrentTemplate(templateId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmationHandler = async () => {
    setOpen(false);
    // setLoader(true);
    try {
      const response = await DeleteReq(
        `delete-email-template/${currentTemplate}`,
        true,
        token
      );
      toast.remove();
      setTemplateList((prevState) =>
        prevState.filter((template) => template._id !== currentTemplate)
      );
      // console.log(response);
      toast.success(response.data.message);
      // const templateResponse = await GetReq("get-email-templates", true, token);
      // templateList(templateResponse.data.templates);
      // setTemplateCount(templateResponse.data.templateCount);
      // setLoader(false);
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  //   function User({ image, name, email }) {
  //     return (
  //       <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
  //         <SoftBox mr={2}>
  //           <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
  //         </SoftBox>
  //         <SoftBox display="flex" flexDirection="column">
  //           <SoftTypography variant="button" fontWeight="medium">
  //             {name}
  //           </SoftTypography>
  //           <SoftTypography variant="caption" color="secondary">
  //             {email}
  //           </SoftTypography>
  //         </SoftBox>
  //       </SoftBox>
  //     );
  //   }

  const columns = [
    { name: "S.No.", align: "center", sort: false },
    { name: "title", align: "left", sort: false },
    { name: "subject", align: "center", sort: false },
    { name: "action", align: "center", sort: false },
  ];

  const rows = [];

  //   const handleSort = async (name) => {
  //     let key = "cratedAt";
  //     if (name === "user") {
  //       if (sortOn === "name" && name[0] !== "-") {
  //         setSortOn(() => "-name");
  //         key = "-name";
  //       } else {
  //         setSortOn(() => "name");
  //         key = "name";
  //       }
  //     } else if (name === "account created" && name[0] !== "-") {
  //       if (sortOn === "createdAt") {
  //         setSortOn(() => "-createdAt");
  //         key = "-createdAt";
  //       } else {
  //         setSortOn(() => "createdAt");
  //         key = "createdAt";
  //       }
  //     } else if (name === "status" && name[0] !== "-") {
  //       if (sortOn === "archived") {
  //         setSortOn(() => "-archived");
  //         key = "-archived";
  //       } else {
  //         setSortOn(() => "archived");
  //         key = "archived";
  //       }
  //     } else if (name === "phone" && name[0] !== "-") {
  //       if (sortOn === "phoneNumber") {
  //         setSortOn(() => "-phoneNumber");
  //         key = "-phoneNumber";
  //       } else {
  //         setSortOn(() => "phoneNumber");
  //         key = "phoneNumber";
  //       }
  //     }

  //     try {
  //       const response = await GetReq(
  //         `user-list?page=${page}&limit=${10}&sort=${key}&search=${search}`,
  //         true,
  //         token
  //       );
  //       setUserList(response.data.users);
  //       setUserCount(response.data.userCount);
  //     } catch (error) {
  //       errorHandler(error);
  //     }
  //     // await fetchData();
  //   };

  //   const pageCount = Math.ceil(userCount / 10);

  if (templateCount > 0) {
    for (const [index, row] of templateList.entries()) {
      rows.push({
        "S.No.": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {index + 1}
          </SoftTypography>
        ),
        title: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {row.title}
          </SoftTypography>
        ),
        subject: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {row.subject}
          </SoftTypography>
        ),
        action: (
          <>
            <Link to={`/edit-email-template/${row._id}`}>
              <SoftButton
                color="info"
                variant="gradient"
                // onClick={handleWhiteSidenav}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
                sx={{ marginRight: "10px" }}
              >
                Edit
              </SoftButton>
            </Link>
            <SoftButton
              color="error"
              variant="gradient"
              sx={{ marginLeft: "10px" }}
              onClick={() => deleteHandler(row._id)}
              // disabled={disabled}
              // sx={sidenavTypeButtonsStyles}
            >
              Delete
            </SoftButton>
          </>
        ),
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loader && <Loader />}
      {!loader && (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                {/* <Stack direction="row" alignItems="center"> */}
                {/* <SoftInput
                  name="search"
                  id="search"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  placeholder="Search User"
                /> */}
                {/* <button
                    style={{
                      padding: "5px 30px",
                      cursor: "pointer",
                      background: "white",
                      border: "0.0625rem solid #d2d6da",
                      borderRadius: "5px",
                    }}
                    type="button"
                    onClick={handleSearch}
                  >
                    <Icon
                      fontSize="30px"
                      sx={{ color: "#d2d6da !important" }}
                      color="#d2d6da !important"
                    >
                      search
                    </Icon>
                  </button> */}
                {/* </Stack> */}
              </Grid>
              <SoftButton
                color="info"
                variant="gradient"
                onClick={() => navigate("/add-email-template")}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
              >
                Add Template
              </SoftButton>
            </Grid>
          </SoftBox>

          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Email Template</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {!loader && (
                <Table
                  columns={columns}
                  rows={rows}
                  docCount={templateCount}
                  message="No template exists"
                  // handleSort={handleSort}
                />
              )}
              <CustomDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                confirmationHandler={confirmationHandler}
                title="Are you sure?"
                content="You want to delete this template?"
                loader={loader}
              />
            </SoftBox>
          </Card>

          {/* {pageCount > 1 && (
            <Card
              sx={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Card>
          )} */}
        </>
      )}
    </DashboardLayout>
  );
};

export default Users;
