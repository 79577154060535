import { useState, useEffect } from "react";

// react-router components
import { Routes, Route } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";

// Soft UI Dashboard React themes
import theme from "assets/theme";

// RTL plugins
import { useSelector } from "react-redux";
import PrivateRoutes from "./routes/PrivateRoutes";
import Dashboard from "layouts/dashboard";
import PublicRoutes from "./routes/PublicRoutes";
import SignIn from "layouts/authentication/sign-in";
import Users from "layouts/user/users";
import Profile from "layouts/profile";
import ForgotPassword from "layouts/authentication/forgot-password";
import ResetPassword from "layouts/authentication/reset-password";
import ChangePassword from "layouts/authentication/change-password";
import UserForm from "layouts/user/user-form";
import EditProfile from "layouts/profile/edit-profile";
import NotFound from "layouts/notFound/notFound";
import CMS from "layouts/cms";
import CMSForm from "layouts/cms/cms-form";
import EmailTemplate from "layouts/emailTemplate";
import EmailTemplateForm from "layouts/emailTemplate/emailTemplateForm";
import Template from "layouts/template";
import TemplateForm from "layouts/template/templateForm";
import Category from "layouts/category";
import CategoryForm from "layouts/category/category-form";
import Content from "layouts/content";
import ContentForm from "layouts/content/content-form";
import ChannelForm from "layouts/channel/channel-form";
import Channel from "layouts/channel";
import Talent from "layouts/talent";
import TalentForm from "layouts/talent/talent-form";
import SubscriptionForm from "layouts/subscription/subscription-form";
import Subscriptions from "layouts/subscription";
import EpisodeForm from "layouts/content/episode-card";

export default function App() {
  let isAuthenticate;
  const { token } = useSelector((state) => state.user);

  if (token) {
    isAuthenticate = true;
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Dashboard />
            </PrivateRoutes>
          }
        />
        <Route
          path="/users"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Users />
            </PrivateRoutes>
          }
        />
        <Route
          path="/cms"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <CMS />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-cms/:cmsId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <CMSForm />
            </PrivateRoutes>
          }
        />
        <Route
          path="/email-template"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <EmailTemplate />
            </PrivateRoutes>
          }
        />
        <Route
          path="/genre"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Category />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-genre"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <CategoryForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-genre/:genreId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <CategoryForm mode="edit" />
            </PrivateRoutes>
          }
        />
        {/* <Route
          path="/movies"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Category />
            </PrivateRoutes>
          }
        /> */}
        <Route
          path="/content"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Content />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-content"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <ContentForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-content/:contentId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <ContentForm mode="edit" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/template"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Template />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-template"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <TemplateForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-template/:templateId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <TemplateForm mode="edit" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-episode/:seriesId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <EpisodeForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-email-template/:templateId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <EmailTemplateForm mode="edit" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-email-template"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <EmailTemplateForm mode="add" />
            </PrivateRoutes>
          }
        />

        <Route
          path="/channel"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Channel />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-channel"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <ChannelForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-channel/:profileId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <ChannelForm mode="edit" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/talent"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Talent />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-talent"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <TalentForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-talent/:profileId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <TalentForm mode="edit" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-user"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <UserForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-user/:userId"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <UserForm mode="edit" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/profile"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Profile />
            </PrivateRoutes>
          }
        />
        <Route
          path="/subscription"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <Subscriptions />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-subscription"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <SubscriptionForm mode="add" />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-profile"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <EditProfile />
            </PrivateRoutes>
          }
        />
        <Route
          path="/change-password"
          exact
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <ChangePassword />
            </PrivateRoutes>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoutes isAuthenticate={isAuthenticate}>
              <SignIn />
            </PublicRoutes>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoutes isAuthenticate={isAuthenticate}>
              <ForgotPassword />
            </PublicRoutes>
          }
        />
        <Route
          path="/reset-password/:resetToken"
          element={
            <PublicRoutes isAuthenticate={isAuthenticate}>
              <ResetPassword />
            </PublicRoutes>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoutes isAuthenticate={isAuthenticate}>
              <NotFound />
            </PrivateRoutes>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
