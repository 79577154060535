import Loader from "components/Loader";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CMS = () => {
  const { token } = useSelector((state) => state.user);

  const [CMSList, setCMSList] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      setLoader(true);
      try {
        const response = await GetReq("get-cms-pages", true, token);
        if (response) {
          setCMSList(response.data.CMSPages);
          setLoader(false);
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    })();
  }, [token]);

  const columns = [
    { name: "s.no.", align: "center", sort: false },
    { name: "title", align: "center", sort: false },
    { name: "action", align: "center", sort: false },
  ];

  const rows = [];

  if (CMSList && CMSList.length > 0) {
    for (const [index, CMS] of CMSList.entries()) {
      console.log({ index });
      rows.push({
        "s.no.": (
          <>
            <SoftTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {index + 1}
            </SoftTypography>
          </>
        ),
        title: (
          <>
            <SoftTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {CMS.title}
            </SoftTypography>
          </>
        ),
        action: (
          <>
            <Link to={`/edit-cms/${CMS._id}`}>
              <SoftButton
                color="info"
                variant="gradient"
                // onClick={handleWhiteSidenav}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
              >
                Edit
              </SoftButton>
            </Link>
          </>
        ),
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Table
          columns={columns}
          rows={rows}
          docCount={(CMSList && CMSList.length) || 0}
          message="No CMS Page exists"
        />
      )}
    </DashboardLayout>
  );
};

export default CMS;
