import Sidenav from "examples/Sidenav";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// Soft UI Dashboard React routes
import routes from "routes";
// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav } from "context";

// Images
import brand from "assets/images/logo-ct.png";
import { Divider } from "@mui/material";
const PrivateRoutes = ({ isAuthenticate, children }) => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  if (isAuthenticate) {
    return (
      <>
        <Sidenav
          color={sidenavColor}
          brand={brand}
          brandName="Soft UI Dashboard"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />

        {children}
      </>
    );
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default PrivateRoutes;
