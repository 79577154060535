import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
// import signInSchema from "../schema/signInSchema";
import { PostReq } from "lib/requests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import { Box, Card, CircularProgress, Grid, Stack } from "@mui/material";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import Loader from "components/Loader";
import { PatchReq } from "lib/requests";
import editProfileSchema from "../schema/editProfileSchema";
import { updateUser } from "store/reducers/userSlice";
// import { addValidation, editValidation } from "../schema/userFormSchema";

function EditProfile() {
  const { token, user } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialState = {
    email: user.email,
    phoneNumber: user.phoneNumber,
    name: user.name,
  };

  const submitHandler = async (values) => {
    try {
      setLoader(true);
      const response = await PatchReq("update-me", values, true, "json", token);
      if (response) {
        const successMessage = response.data.message;
        dispatch(updateUser({ user: response.data.updatedUser }));
        toast.remove();
        toast.success(successMessage);
        setLoader(false);
        navigate("/profile", { replace: true });
      }
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Grid
          container
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  Edit Profile
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  validationSchema={editProfileSchema}
                  onSubmit={(values) => submitHandler(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftBox>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Name
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="name"
                            id="name"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            placeholder="Name"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.name && touched.name && errors.name}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Phone Number
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="phoneNumber"
                            id="phoneNumber"
                            value={values.phoneNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="tel"
                            placeholder="Phone Number"
                            disabled={true}
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Email
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="email"
                            id="email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            placeholder="Email"
                            disabled={true}
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.email && touched.email && errors.email}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mt={4} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            Update Profile
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default EditProfile;
