import axios from "axios";
import { BASE_URL } from "../constants";

export const GetReq = async (url, auth, token) => {
  const headers = {};

  if (auth && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return axios.get(`${BASE_URL}/${url}`, { headers });
};

export const PostReq = async (url, body, auth, dataType, token) => {
  const headers = {};

  if (dataType === "json") {
    headers["Content-Type"] = "application/json";
  } else {
    headers["Content-Type"] = "multipart/form-data";
    headers["Accept"] = "multipart/form-data";
  }

  if (auth && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  console.log({ headers });

  return axios.post(`${BASE_URL}/${url}`, body, {
    headers,
  });
};

export const PatchReq = async (url, body, auth, dataType, token) => {
  const headers = {};

  if (dataType === "json") {
    headers["content-type"] = "application/json";
  } else {
    headers["content-type"] = "multipart/form-data";
  }

  if (auth && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return axios.patch(`${BASE_URL}/${url}`, body, {
    headers,
  });
};

export const DeleteReq = async (url, auth, token) => {
  const headers = {};

  if (auth && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return axios.delete(`${BASE_URL}/${url}`, {
    headers,
  });
};
