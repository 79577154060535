import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GetReq } from "lib/requests";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "examples/Tables/Table";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { DeleteReq } from "lib/requests";
import { toast } from "react-hot-toast";
import { errorHandler } from "hooks/methods";
import CustomDialog from "components/Dialog";
import Loader from "components/Loader";
import SoftBadge from "components/SoftBadge";
import CustomCard from "components/CustomCard";
import SoftInput from "components/SoftInput";
import SearchIcon from "@mui/icons-material/Search";

const Content = () => {
  const { token } = useSelector((state) => state.user);

  const [contentList, setContentList] = useState([]);
  const [contentCount, setContentCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [currentContent, setCurrentContent] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortOn, setSortOn] = useState("createdAt");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(
          `content?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
          true,
          token
        );
        setContentList(response.data.contents);
        setContentCount(response.data.contentCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token, page]);

  const handleChange = async (event, value) => {
    setPage(value);
    try {
      const response = await GetReq(
        `content?page=${value}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setContentList(response.data.contents);
      setContentCount(response.data.contentCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);

    try {
      const response = await GetReq(
        `content?page=${page}&limit=${10}&sort=${sortOn}&search=${
          event.target.value
        }`,
        true,
        token
      );
      setContentList(response.data.contents);
      setContentCount(response.data.contentCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteHandler = async (contentId) => {
    setCurrentContent(contentId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmationHandler = async () => {
    setOpen(false);
    setLoader(true);
    try {
      const response = await DeleteReq(
        `content/${currentContent}`,
        true,
        token
      );
      toast.remove();
      setContentList((prevState) =>
        prevState.filter((content) => content._id !== currentContent)
      );
      toast.success(response.data.message);
      const contentResponse = await GetReq(
        `content?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setContentList(contentResponse.data.contents);
      setContentCount(contentResponse.data.contentCount);
      setLoader(false);
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  function Content({ title }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {title}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const columns = [
    { name: "content", align: "left", sort: true },
    { name: "content Type", align: "center", sort: true },
    { name: "genre", align: "center", sort: false },
    { name: "language", align: "center", sort: false },
    { name: "content created", align: "center", sort: true },
    { name: "status", align: "center", sort: true },
    { name: "action", align: "left", sort: false },
  ];

  const rows = [];

  const handleSort = async (name) => {
    let key = "cratedAt";
    if (name === "content") {
      if (sortOn === "content" && name[0] !== "-") {
        setSortOn(() => "-translations.en.title");
        key = "-translations.en.title";
      } else {
        setSortOn(() => "translations.en.title");
        key = "translations.en.title";
      }
    } else if (name === "content created" && name[0] !== "-") {
      if (sortOn === "createdAt") {
        setSortOn(() => "-createdAt");
        key = "-createdAt";
      } else {
        setSortOn(() => "createdAt");
        key = "createdAt";
      }
    } else if (name === "status" && name[0] !== "-") {
      if (sortOn === "archived") {
        setSortOn(() => "-archived");
        key = "-archived";
      } else {
        setSortOn(() => "archived");
        key = "archived";
      }
    } else if (name === "content Type" && name[0] !== "-") {
      if (sortOn === "contentType") {
        setSortOn(() => "-contentType");
        key = "-contentType";
      } else {
        setSortOn(() => "contentType");
        key = "contentType";
      }
    }

    try {
      const response = await GetReq(
        `content?page=${page}&limit=${10}&sort=${key}&search=${search}`,
        true,
        token
      );
      setContentList(response.data.contents);
      setContentCount(response.data.contentCount);
    } catch (error) {
      errorHandler(error);
    }
    // await fetchData();
  };

  const pageCount = Math.ceil(contentCount / 10);

  for (const row of contentList) {
    rows.push({
      content: <Content title={row.translations?.en?.title} />,
      "content created": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {new Date(row.createdAt).getDate()} /
          {new Date(row.createdAt).getMonth()} /
          {new Date(row.createdAt).getFullYear()}
        </SoftTypography>
      ),
      "content Type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.contentType}
        </SoftTypography>
      ),
      genre: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.genre &&
            row.genre.length > 0 &&
            row.genre.map((_genre) => (
              <> {_genre?.translations?.en?.title || "-"}</>
            ))}
          {(!row.genre || row.genre.length === 0) && <> {"-"}</>}
        </SoftTypography>
      ),
      language: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.language}
        </SoftTypography>
      ),
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent={row.archived === false ? "active" : "Inactive"}
          color={row.archived === false ? "success" : "error"}
          size="xs"
          container
        />
      ),
      action: (
        <>
          <Link to={`/edit-content/${row._id}?content-type=${row.contentType}`}>
            <SoftButton
              color="info"
              variant="gradient"
              // onClick={handleWhiteSidenav}
              // disabled={disabled}
              // sx={sidenavTypeButtonsStyles}
              sx={{ marginRight: "10px" }}
            >
              Edit
            </SoftButton>
          </Link>
          <SoftButton
            color="error"
            variant="gradient"
            sx={{ marginLeft: "10px" }}
            onClick={() => deleteHandler(row._id)}
            // disabled={disabled}
            // sx={sidenavTypeButtonsStyles}
          >
            Delete
          </SoftButton>
          {row.contentType === "series" && (
            <Link to={`/add-episode/${row._id}`}>
              <SoftButton
                color="info"
                variant="gradient"
                sx={{ marginLeft: "10px" }}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
              >
                Episodes
              </SoftButton>
            </Link>
          )}
        </>
      ),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                {/* <Stack direction="row" alignItems="center"> */}
                <SoftInput
                  name="search"
                  id="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search Content"
                />
                {/* <button
                    style={{
                      padding: "5px 30px",
                      cursor: "pointer",
                      background: "white",
                      border: "0.0625rem solid #d2d6da",
                      borderRadius: "5px",
                    }}
                    type="button"
                    onClick={handleSearch}
                  >
                    <Icon
                      fontSize="30px"
                      sx={{ color: "#d2d6da !important" }}
                      color="#d2d6da !important"
                    >
                      search
                    </Icon>
                  </button> */}
                {/* </Stack> */}
              </Grid>
              <SoftButton
                color="info"
                variant="gradient"
                onClick={() => navigate("/add-content")}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
              >
                Add Content
              </SoftButton>
            </Grid>
          </SoftBox>

          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Contents table</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {!loader && (
                <Table
                  columns={columns}
                  rows={rows}
                  docCount={contentCount}
                  handleSort={handleSort}
                  message="No content exists."
                />
              )}
              <CustomDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                confirmationHandler={confirmationHandler}
                title="Are you sure?"
                content="You want to delete this content?"
                loader={loader}
              />
            </SoftBox>
          </Card>

          {pageCount > 1 && (
            <Card
              sx={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Card>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Content;
