import { Card } from "@mui/material";
import Loader from "components/Loader";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import CKEditorComponent from "examples/CKEditor";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Formik } from "formik";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PatchReq } from "lib/requests";
import { toast } from "react-hot-toast";
import emailTemplateSchema from "../schema/emailTemplateSchema";
import { PostReq } from "lib/requests";
const EmailTemplateForm = ({ mode }) => {
  const { templateId } = useParams();

  const [loader, setLoader] = useState(true);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [data, setData] = useState("");

  const navigate = useNavigate();

  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (mode === "edit") {
      (async () => {
        setLoader(true);
        try {
          const response = await GetReq(
            `get-email-template/${templateId}`,
            true,
            token
          );
          if (response) {
            setCurrentTemplate(response.data.template);
            setData(response.data.template.body);
            setLoader(false);
          }
        } catch (error) {
          errorHandler(error);
          setLoader(false);
        }
      })();
    } else {
      setLoader(false);
    }
  }, [token, templateId, mode]);

  if (currentTemplate) {
  }
  const initialState = {
    title: "",
    subject: "",
    slug: "",
  };

  if (currentTemplate) {
    initialState.title = currentTemplate.title;
    initialState.subject = currentTemplate.subject;
    initialState.slug = currentTemplate.slug;
  }

  const submitHandler = async (values) => {
    if (mode === "add") {
      try {
        const response = await PostReq(
          `create-email-template`,
          {
            title: values.title,
            subject: values.subject,
            body: data,
            slug: values.slug,
          },
          true,
          "json",
          token
        );
        if (response) {
          toast.success(response.data.message);
          setLoader(false);
          navigate("/email-template");
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    } else {
      try {
        const response = await PatchReq(
          `edit-email-template/${templateId}`,
          {
            title: values.title,
            subject: values.subject,
            body: data,
            slug: values.slug,
          },
          true,
          "json",
          token
        );
        if (response) {
          toast.success(response.data.message);
          setLoader(false);
          navigate("/email-template");
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <SoftBox p={3}>
          <Card sx={{ padding: "10px", overflow: "visible" }}>
            <SoftBox p={3} mb={1} textAlign="center">
              <SoftTypography
                variant="h5"
                fontWeight="medium"
                textTransform="uppercase"
              >
                {mode === "add" ? "Add template" : "edit template"}
              </SoftTypography>
            </SoftBox>
            <SoftBox p={3} mb={1}>
              <Formik
                initialValues={initialState}
                validationSchema={emailTemplateSchema}
                onSubmit={(values) => submitHandler(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <SoftBox>
                      <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Title
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          name="title"
                          id="title"
                          value={values.title}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          placeholder="Title"
                        />
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          color="error"
                        >
                          {errors.title && touched.title && errors.title}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Subject
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          name="subject"
                          id="subject"
                          value={values.subject}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          placeholder="Subject"
                        />
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          color="error"
                        >
                          {errors.subject && touched.subject && errors.subject}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox mb={2}>
                        <CKEditorComponent data={data} setData={setData} />
                      </SoftBox>

                      <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Slug
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          name="slug"
                          id="slug"
                          value={values.slug}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          placeholder="Slug"
                          disabled={mode === "edit"}
                        />
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          color="error"
                        >
                          {errors.slug && touched.slug && errors.slug}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          fullWidth
                        >
                          {mode === "add" ? "Add template" : "Edit template"}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </form>
                )}
              </Formik>
            </SoftBox>
          </Card>
        </SoftBox>
      )}
    </DashboardLayout>
  );
};

export default EmailTemplateForm;
