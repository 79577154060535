import { Card, Grid, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ImagePreview from "components/ImagePreview";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { PostReq } from "lib/requests";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const EpisodeForm = () => {
  const { token } = useSelector((state) => state.user);

  const { seriesId } = useParams();

  const [initialState, setInitialState] = useState([
    {
      en: {
        title: "",
        plot: "",
      },
      sp: {
        title: "",
        plot: "",
      },
      poster: "",
      url: "",
      episodeNumber: 0,
      // value: 0,
      releaseDate: "",
      duration: 0,
    },
  ]);

  const [errorHandler, setErrorHandler] = useState(null);
  const [value, setValue] = useState(0);
  const [plotValue, setPlotValue] = useState(0);

  const posterInputRef = useRef();

  const handleCard = () => {
    setInitialState((prevState) => [
      ...prevState,
      {
        en: {
          title: "",
          plot: "",
        },
        sp: {
          title: "",
          plot: "",
        },
        poster: "",
        url: "",
        episodeNumber: 0,
        // value: 0,
        releaseDate: "",
        duration: 0,
      },
    ]);
  };

  const handleChange = async (event, index, field) => {
    const data = [...initialState];
    if (field === "file") {
      const formData = new FormData();
      formData.append("poster", event.target.files[0]);
      const response = await PostReq(
        "poster-upload",
        formData,
        true,
        "multipart/form-data",
        token
      );
      if (response) {
        data[index][event.target.name] = response.data.poster;
      }
    } else if (field === "date") {
      data[index]["releaseDate"] = event;
    } else {
      let exists = false;
      if (event.target.name === "episodeNumber") {
        for (const _field of initialState) {
          if (_field.episodeNumber === event.target.value) {
            exists = true;
          }
        }
        if (exists) {
          setErrorHandler((prevState) => [
            ...prevState,
            { [index]: "This episode already exists" },
          ]);
        } else {
          const _data = [];
          if (errorHandler && errorHandler.length > 0) {
            for (const obj of errorHandler) {
              if (obj[index]) {
                continue;
              } else {
                _data.push(obj);
              }
            }
          }

          setErrorHandler([..._data]);
        }
      }

      if (event.target.name === "en.title") {
        data[index]["en"]["title"] = event.target.value;
      } else if (event.target.name === "sp.title") {
        data[index]["sp"]["title"] = event.target.value;
      } else if (event.target.name === "en.plot") {
        data[index]["en"]["plot"] = event.target.value;
      } else if (event.target.name === "sp.plot") {
        data[index]["sp"]["plot"] = event.target.value;
      } else {
        data[index][event.target.name] = event.target.value;
      }
    }
    setInitialState([...data]);
    // setInitialState(
    //   (prevState) => (prevState[index][event.target.name] = event.target.value)
    // );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await PostReq(
        "upload-episode",
        { relatedTo: seriesId, data: initialState },
        true,
        "json",
        token
      );

      if (response) {
        console.log({ response });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <form onSubmit={handleSubmit}>
            {initialState.map((_fields, index) => {
              return (
                <Card sx={{ padding: "50px" }}>
                  <Grid item xs={10}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Poster
                            </SoftTypography>
                          </SoftBox>
                          <input
                            name="poster"
                            id="poster"
                            hidden
                            ref={posterInputRef}
                            // value={initialState[index].poster}
                            // onBlur={handleBlur}
                            //   disabled={mode === "edit"}
                            onChange={(event) =>
                              handleChange(event, index, "file")
                            }
                            type="file"
                            placeholder="Content Poster"
                          />
                          <SoftButton
                            type="button"
                            variant="gradient"
                            color="info"
                            fullWidth
                            onClick={() => posterInputRef.current.click()}
                          >
                            Add Poster
                          </SoftButton>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {/* {errors.email && touched.email && errors.email} */}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      {initialState[index].poster && (
                        <ImagePreview image={initialState[index].poster} />
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={6}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Title
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={6}>
                        <Card
                          sx={{ padding: "5px" }}
                          style={{ backgroundColor: "#f0f1f2" }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              <SoftButton
                                type="button"
                                onClick={() => setValue(0)}
                                disabled={value === 0}
                                fullWidth
                                sx={{ padding: "1px !important" }}
                              >
                                English
                              </SoftButton>
                            </Grid>
                            <Grid item xs={6}>
                              <SoftButton
                                onClick={() => setValue(1)}
                                type="button"
                                disabled={value === 1}
                                fullWidth
                              >
                                Spainesh
                              </SoftButton>
                            </Grid>
                          </Stack>
                        </Card>
                      </Grid>
                    </Stack>
                  </Grid>
                  {value === 0 && (
                    <SoftBox mb={2}>
                      <SoftInput
                        name="en.title"
                        id="en.title"
                        value={initialState[index].en["title"]}
                        // onBlur={handleBlur}
                        onChange={(event) => handleChange(event, index)}
                        // onChange={(event) => {
                        //   setFieldValue("en.title", event.target.value);
                        // }}
                        type="text"
                        placeholder="Title"
                      />
                    </SoftBox>
                  )}
                  {value === 1 && (
                    <SoftBox mb={2}>
                      <SoftInput
                        name="sp.title"
                        id="sp.title"
                        value={initialState[index].sp["title"]}
                        // onBlur={handleBlur}
                        onChange={(event) => handleChange(event, index)}
                        // onChange={(event) => {
                        //   setFieldValue("en.title", event.target.value);
                        // }}
                        type="text"
                        placeholder="Title"
                      />
                    </SoftBox>
                  )}

                  <Grid item xs={12} mb={2}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={6}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Plot
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={6}>
                        <Card
                          sx={{ padding: "5px" }}
                          style={{ backgroundColor: "#f0f1f2" }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              <SoftButton
                                type="button"
                                onClick={() => setPlotValue(0)}
                                disabled={plotValue === 0}
                                fullWidth
                                sx={{ padding: "1px !important" }}
                              >
                                English
                              </SoftButton>
                            </Grid>
                            <Grid item xs={6}>
                              <SoftButton
                                onClick={() => setPlotValue(1)}
                                type="button"
                                disabled={plotValue === 1}
                                fullWidth
                              >
                                Spanish
                              </SoftButton>
                            </Grid>
                          </Stack>
                        </Card>
                      </Grid>
                    </Stack>
                  </Grid>
                  {plotValue === 0 && (
                    <SoftBox mb={2}>
                      <SoftInput
                        name="en.plot"
                        id="en.plot"
                        value={initialState[index].en["plot"]}
                        // onBlur={handleBlur}
                        onChange={(event) => handleChange(event, index)}
                        // onChange={(event) => {
                        //   setFieldValue("en.plot", event.target.value);
                        // }}
                        type="text"
                        placeholder="Plot"
                      />
                    </SoftBox>
                  )}
                  {plotValue === 1 && (
                    <SoftBox mb={2}>
                      <SoftInput
                        name="sp.plot"
                        id="sp.plot"
                        value={initialState[index].sp["plot"]}
                        // onBlur={handleBlur}
                        onChange={(event) => handleChange(event, index)}
                        // onChange={(event) => {
                        //   setFieldValue("en.plot", event.target.value);
                        // }}
                        type="text"
                        placeholder="Plot"
                      />
                    </SoftBox>
                  )}
                  <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        URL
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      name="url"
                      id="url"
                      value={initialState[index].url}
                      // onBlur={handleBlur}
                      onChange={(event) => handleChange(event, index)}
                      type="text"
                      placeholder="Url"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Episode Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      name="episodeNumber"
                      id="episodeNumber"
                      value={initialState[index].episodeNumber}
                      // onBlur={handleBlur}
                      //   disabled={mode === "edit"}
                      onChange={(event) => handleChange(event, index)}
                      type="number"
                      placeholder="Content Episode Number in minutes"
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="error"
                    >
                      {/* {errors.email && touched.email && errors.email} */}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Duration
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      name="duration"
                      id="duration"
                      value={initialState[index].duration}
                      //   disabled={mode === "edit"}
                      onChange={(event) => handleChange(event, index)}
                      type="number"
                      placeholder="Content duration in minutes"
                    />
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="error"
                    >
                      {/* {errors.email && touched.email && errors.email} */}
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Release Date
                      </SoftTypography>
                    </SoftBox>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          id="releaseDate"
                          name="releaseDate"
                          value={initialState[index].releaseDate}
                          onChange={(date) => handleChange(date, index, "date")}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="error"
                    >
                      {/* {errors.email && touched.email && errors.email} */}
                    </SoftTypography>
                  </SoftBox>
                  {errorHandler &&
                    errorHandler.length > 0 &&
                    errorHandler.map((error, _index) => {
                      if (error[index]) {
                        return (
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {error[index]}
                          </SoftTypography>
                        );
                      } else {
                        return <></>;
                      }
                    })}

                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        {index === initialState.length - 1 && (
                          <SoftButton
                            fullWidth
                            type="button"
                            onClick={handleCard}
                          >
                            Add
                          </SoftButton>
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        <SoftButton type="submit">Add Episode</SoftButton>
                      </Grid>
                    </Stack>
                  </Grid>
                </Card>
              );
            })}
          </form>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default EpisodeForm;
