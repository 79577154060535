import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoutes = ({ isAuthenticate, children }) => {
  if (isAuthenticate) {
    return <Navigate to="/" replace />;
  } else {
    console.log("else");
    return children;
  }
};

export default PublicRoutes;
