import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import forgot from "assets/images/forgot.jpg";
import signInSchema from "../schema/signInSchema";
import { PostReq } from "lib/requests";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import forgotPasswordSchema from "../schema/forgotPasswordSchema";
import { Icon } from "@mui/material";
import { errorHandler } from "hooks/methods";

function ForgotPassword() {
  const initialState = {
    email: "",
    password: "",
  };

  const submitHandler = async (values) => {
    try {
      const response = await PostReq("forgot-password", values, false, "json");
      if (response) {
        toast.remove();
        toast.success(response.data.message);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <CoverLayout
      title="Forgot Password"
      description="Enter your email to reset your password"
      image={forgot}
    >
      <Formik
        initialValues={initialState}
        validationSchema={forgotPasswordSchema}
        onSubmit={(values) => submitHandler(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <SoftBox>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Email
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="email"
                  id="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  placeholder="Email"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errors.email && touched.email && errors.email}
                </SoftTypography>
              </SoftBox>

              {/* <SoftBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;&nbsp;Remember me
                </SoftTypography>
              </SoftBox> */}
              <SoftBox display="flex" alignItems="center">
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  &nbsp;&nbsp;
                  <Link
                    to="/login"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Icon sx={{ fontWeight: "bold", marginTop: "5px" }}>
                      arrow_back
                    </Icon>
                    Go Back
                  </Link>
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={1}>
                <SoftButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  Submit
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </form>
        )}
      </Formik>
    </CoverLayout>
  );
}

export default ForgotPassword;
