import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Formik } from "formik";
import React from "react";
import CommonForm from "./common-form";

const ContentForm = ({ mode }) => {
  console.log(mode);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CommonForm mode={mode} />
    </DashboardLayout>
  );
};

export default ContentForm;
