import { Avatar } from "@mui/material";
import { IMAGE_BASE_URL } from "constants";
import React, { useState } from "react";

const ImagePreview = ({ file, image }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  console.log({ preview });

  return (
    <>
      {(preview || image) && (
        <Avatar
          src={preview || (image && `${IMAGE_BASE_URL}${image}`)}
          alt="preview"
          variant="square"
          sx={{ width: "150px", height: "150px" }}
        />
      )}
    </>
  );
};

export default ImagePreview;
