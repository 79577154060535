import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ImagePreview from "components/ImagePreview";
import Loader from "components/Loader";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Formik } from "formik";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import { PatchReq } from "lib/requests";
import { PostReq } from "lib/requests";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const TemplateForm = ({ mode }) => {
  const [value, setValue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const mediaInputRef = useRef();
  // const [templateIdValue, setTemplateId] = useState("");

  const { token } = useSelector((state) => state.user);

  const { templateId } = useParams();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  const navigate = useNavigate();

  const initialState = {
    en: {
      title: "",
      subTitle: "",
    },
    sp: {
      title: "",
      subTitle: "",
    },
    type: "",
    media: "",
    status: true,
  };

  const submitHandler = (values, resetForm) => {
    if (mode.toLowerCase() === "add") {
      const formData = new FormData();
      const { status, ...restValues } = values;
      restValues.archived = !status;
      formData.append("en", JSON.stringify(restValues.en));
      formData.append("sp", JSON.stringify(restValues.sp));
      formData.append("media", restValues.media);
      formData.append("type", restValues.type);
      formData.append("archived", restValues.archived);
      (async () => {
        try {
          // setLoader(true);
          const response = await PostReq(
            "add-template",
            formData,
            true,
            "multipart",
            token
          );
          if (response) {
            const successMessage = response.data.message;
            toast.remove();
            toast.success(successMessage);
            //   setLoader(false);
            //   navigate("/users", { replace: true });
          }
        } catch (error) {
          errorHandler(error);
          // setLoader(false);
        }
        resetForm();
      })();
    } else {
      const formData = new FormData();
      const { status, ...restValues } = values;
      restValues.archived = !status;
      formData.append("en", JSON.stringify(restValues.en));
      formData.append("sp", JSON.stringify(restValues.sp));
      if (restValues.media) formData.append("media", restValues.media);
      formData.append("type", restValues.type);
      formData.append("archived", restValues.archived);
      (async () => {
        try {
          // setLoader(true);
          const response = await PatchReq(
            `template/${templateId}`,
            formData,
            true,
            "multipart",
            token
          );
          if (response) {
            const successMessage = response.data.message;
            toast.remove();
            toast.success(successMessage);
            //   setLoader(false);
            //   navigate("/users", { replace: true });
          }
        } catch (error) {
          errorHandler(error);
          // setLoader(false);
        }
        resetForm();
      })();
    }

    navigate("/template");
  };

  const tabChangeHandler = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (mode === "edit") {
      setLoader(true);
      (async () => {
        try {
          const templateResponse = await GetReq(
            `template/${templateId}`,
            true,
            token
          );

          if (templateResponse) {
            setCurrentTemplate(templateResponse.data.template);
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
          errorHandler(error);
        }
      })();
    }
  }, [mode, templateId, token]);

  if (currentTemplate) {
    initialState.en = currentTemplate.translations.en;
    initialState.sp = currentTemplate.translations.sp;
    initialState.type = currentTemplate.type;
    initialState.status = !currentTemplate.archived;
    initialState.image = currentTemplate.media;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Grid
          container
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  Add Template
                  {/* {mode === "add" ? "Add Template" : "Edit Template"} */}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  // validationSchema={
                  //   mode === "add" ? addValidation : editValidation
                  // }
                  onSubmit={(values, { resetForm }) =>
                    submitHandler(values, resetForm)
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftBox>
                        <Tabs
                          value={value}
                          onChange={tabChangeHandler}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="scrollable force tabs example"
                        >
                          <Tab label="English" />
                          <Tab label="Spainesh" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                English Title
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              name="en.title"
                              id="en.title"
                              value={values.en.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              placeholder="Enter title in english"
                            />
                            {/* <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.name && touched.name && errors.name}
                          </SoftTypography> */}
                          </SoftBox>
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                English Sub-Title
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              name="en.subTitle"
                              id="en.subTitle"
                              value={values.en.subTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              //   disabled={mode === "edit"}
                              type="text"
                              placeholder="Enter sub-title in english"
                            />
                            {/* <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber}
                          </SoftTypography> */}
                          </SoftBox>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Spanish Title
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              name="sp.title"
                              id="sp.title"
                              value={values.sp.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              placeholder="Enter title in spanish"
                            />
                            {/* <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.name && touched.name && errors.name}
                          </SoftTypography> */}
                          </SoftBox>
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Spanish Sub-Title
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              name="sp.subTitle"
                              id="sp.subTitle"
                              value={values.sp.subTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              //   disabled={mode === "edit"}
                              type="text"
                              placeholder="Enter sub-title in spanish"
                            />
                            {/* <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.phoneNumber &&
                              touched.phoneNumber &&
                              errors.phoneNumber}
                          </SoftTypography> */}
                          </SoftBox>
                        </TabPanel>
                        <Divider />
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Type
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="type"
                            id="type"
                            value={values.type}
                            onBlur={handleBlur}
                            //   disabled={mode === "edit"}
                            onChange={handleChange}
                            type="text"
                            placeholder="Template Type"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {/* {errors.email && touched.email && errors.email} */}
                          </SoftTypography>
                        </SoftBox>

                        <Grid item xs={10}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Media
                                  </SoftTypography>
                                </SoftBox>
                                <input
                                  name="file"
                                  id="file"
                                  hidden
                                  ref={mediaInputRef}
                                  //   value={values.type}
                                  onBlur={handleBlur}
                                  //   disabled={mode === "edit"}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "media",
                                      event.target.files[0]
                                    );
                                  }}
                                  type="file"
                                  placeholder="Media"
                                />
                                <SoftButton
                                  type="button"
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  onClick={() => mediaInputRef.current.click()}
                                >
                                  Add Media
                                </SoftButton>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {/* {errors.email && touched.email && errors.email} */}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                            {(values.media || values.image) && (
                              <ImagePreview
                                image={values.image}
                                file={values.media}
                              />
                            )}
                          </Stack>
                        </Grid>

                        <SoftBox mt={3} mb={2} lineHeight={1}>
                          <SoftTypography variant="h6">Status</SoftTypography>
                          <Switch
                            id="status"
                            name="status"
                            checked={values.status}
                            onChange={handleChange}
                            value={values.status}
                          />
                        </SoftBox>

                        <SoftBox mt={4} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {mode === "add" ? "Add Template" : "Edit Template"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default TemplateForm;
