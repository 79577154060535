import { toast } from "react-hot-toast";
import store from "../store/index";
import { logout } from "store/reducers/userSlice";

export const errorHandler = (error) => {
  toast.remove();
  if (
    error.response &&
    error.response.data &&
    error.response.data.message &&
    (error.response.data.message.toLowerCase() === "jwt expired" ||
      error.response.data.message.toLowerCase() ===
        "user not found with this token")
  ) {
    store.dispatch(logout());
    toast.error("Your token expired login again.");
  } else {
    toast.error(error.response.data.message || error.message);
  }
};
