import { Card, Grid, Icon, Pagination } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GetReq } from "lib/requests";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "examples/Tables/Table";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { DeleteReq } from "lib/requests";
import { toast } from "react-hot-toast";
import { errorHandler } from "hooks/methods";
import CustomDialog from "components/Dialog";
import Loader from "components/Loader";
import SoftBadge from "components/SoftBadge";
import SoftInput from "components/SoftInput";

const Category = () => {
  const { token } = useSelector((state) => state.user);

  const [categoryList, setCategoryList] = useState([]);
  const [categoryCount, setCategoryCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortOn, setSortOn] = useState("createdAt");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(
          `genre?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
          true,
          token
        );
        setCategoryList(response.data.data);
        setCategoryCount(response.data.genreCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token, page]);

  const handleChange = async (event, value) => {
    setPage(value);
    try {
      const response = await GetReq(
        `genre?page=${value}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setCategoryList(response.data.data);
      setCategoryCount(response.data.genreCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);

    try {
      const response = await GetReq(
        `genre?page=${page}&limit=${10}&sort=${sortOn}&search=${
          event.target.value
        }`,
        true,
        token
      );
      setCategoryList(response.data.data);
      setCategoryCount(response.data.genreCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteHandler = async (categoryId) => {
    setCurrentCategory(categoryId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmationHandler = async () => {
    setOpen(false);
    setLoader(true);
    try {
      const response = await DeleteReq(`genre/${currentCategory}`, true, token);
      toast.remove();
      setCategoryList((prevState) =>
        prevState.filter((_category) => _category._id !== currentCategory)
      );
      toast.success(response.data.message);
      const categoryResponse = await GetReq(
        `genre?page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setCategoryList(categoryResponse.data.data);
      setCategoryCount(categoryResponse.data.genreCount);
      setLoader(false);
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  function Category({ title }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftTypography variant="button" fontWeight="medium">
          {title}
        </SoftTypography>
      </SoftBox>
    );
  }

  const columns = [
    { name: "genre", align: "center", sort: true },
    { name: "genre created", align: "center", sort: true },
    { name: "status", align: "center", sort: true },
    { name: "action", align: "center", sort: false },
  ];

  const rows = [];

  const handleSort = async (name) => {
    let key = "cratedAt";
    if (name === "genre") {
      if (sortOn === "title" && name[0] !== "-") {
        setSortOn(() => "-title");
        key = "-title";
      } else {
        setSortOn(() => "title");
        key = "title";
      }
    } else if (name === "account created" && name[0] !== "-") {
      if (sortOn === "createdAt") {
        setSortOn(() => "-createdAt");
        key = "-createdAt";
      } else {
        setSortOn(() => "createdAt");
        key = "createdAt";
      }
    } else if (name === "status" && name[0] !== "-") {
      if (sortOn === "archived") {
        setSortOn(() => "-archived");
        key = "-archived";
      } else {
        setSortOn(() => "archived");
        key = "archived";
      }
    }

    try {
      const response = await GetReq(
        `genre?page=${page}&limit=${10}&sort=${key}&search=${search}`,
        true,
        token
      );
      setCategoryList(response.data.data);
      setCategoryCount(response.data.genreCount);
    } catch (error) {
      errorHandler(error);
    }
    // await fetchData();
  };

  const pageCount = Math.ceil(categoryCount / 10);

  for (const row of categoryList) {
    rows.push({
      genre: <Category title={row.title} />,
      "genre created": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {new Date(row.createdAt).getDate()} /
          {new Date(row.createdAt).getMonth()} /
          {new Date(row.createdAt).getFullYear()}
        </SoftTypography>
      ),
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent={row.archived === false ? "active" : "Inactive"}
          color={row.archived === false ? "success" : "error"}
          size="xs"
          container
        />
      ),
      action: (
        <>
          <Link to={`/edit-genre/${row._id}`}>
            <SoftButton
              color="info"
              variant="gradient"
              sx={{ marginRight: "10px" }}
            >
              Edit
            </SoftButton>
          </Link>
          <SoftButton
            color="error"
            variant="gradient"
            sx={{ marginLeft: "10px" }}
            onClick={() => deleteHandler(row._id)}
          >
            Delete
          </SoftButton>
        </>
      ),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loader && <Loader />}
      {!loader && (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                {/* <Stack direction="row" alignItems="center"> */}
                <SoftInput
                  name="search"
                  id="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search User"
                />
              </Grid>
              <SoftButton
                color="info"
                variant="gradient"
                onClick={() => navigate("/add-genre")}
              >
                Add Genre
              </SoftButton>
            </Grid>
          </SoftBox>

          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Genres table</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {!loader && (
                <Table
                  columns={columns}
                  rows={rows}
                  docCount={categoryCount}
                  handleSort={handleSort}
                  message="No genre exists."
                />
              )}
              <CustomDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                confirmationHandler={confirmationHandler}
                title="Are you sure?"
                content="You want to delete this user?"
                loader={loader}
              />
            </SoftBox>
          </Card>

          {pageCount > 1 && (
            <Card
              sx={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Card>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Category;
