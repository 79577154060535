import {
  Card,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GetReq, PostReq } from "lib/requests";
import { useSelector } from "react-redux";
import { errorHandler } from "hooks/methods";
import Loader from "components/Loader";
import ImagePreview from "components/ImagePreview";
import VideoPreview from "components/VideoPreview";
import Icon from "@mui/material/Icon";
import CastForm from "./cast-form";
import MultiLangForm from "./multi-lang-form";
import DetailForm from "./detail-form";
import FormButtons from "./form-button";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

const CommonForm = ({ mode }) => {
  const posterInputRef = useRef();
  const trailerInputRef = useRef();

  const navigate = useNavigate();

  const [languages, setLanguage] = useState([
    { id: 1, name: "Spainesh" },
    { id: 2, name: "English" },
  ]);

  const [genres, setGenres] = useState([]);
  const [producers, setProducers] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([
    { id: null, name: "" },
  ]);
  const [errorMessage, setErrorMessage] = useState({});
  // const [errorField, setErrorField] = useState("");
  const [technicians, setTechnicians] = useState([]);
  const [rating, setRating] = useState([]);
  const [loader, setLoader] = useState(true);
  const [contentType, setContentType] = useState("");
  const [value, setValue] = useState(0);
  const [formCount, setFormCount] = useState(0);

  const [selectedEpisode, setSelectedEpisode] = useState([
    { noOfEpisode: null, url: "" },
  ]);

  const { token } = useSelector((state) => state.user);

  const multiSelectStyle = {
    inputField: {
      borderRadius: "0.75rem",
    },
    chips: {
      background: "#e9ecef",
      color: "black",
    },
    optionContainer: {
      borderRadius: "0.75rem",
      border: "none",
      fontSize: "0.875rem",
    },
    option: {
      borderRadius: "0.75rem",
      color: "#344767",
    },
  };

  const handleSelect = (event, values, field) => {
    values[field] = [...event];
  };

  const handleRemove = (event, values, field) => {
    values[field] = [...event];
  };

  const tabChangeHandler = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      try {
        const [
          genreResponse,
          producerResponse,
          directorResponse,
          artistResponse,
          technicianResponse,
          ratingResponse,
        ] = await Promise.all([
          GetReq("genre", true, token),
          GetReq("user-by-role?role=producer", true, token),
          GetReq("user-by-role?role=director", true, token),
          GetReq("user-by-role?role=artist", true, token),
          GetReq("user-by-role?role=technician", true, token),
          GetReq("rating", true, token),
        ]);
        if (
          genreResponse &&
          producerResponse &&
          directorResponse &&
          artistResponse &&
          technicianResponse
        ) {
          setLoader(false);
          setGenres([...genreResponse.data.data]);
          setProducers([...producerResponse.data.users]);
          setDirectors([...directorResponse.data.users]);
          setArtists([...artistResponse.data.users]);
          setTechnicians([...technicianResponse.data.users]);
          setRating([...ratingResponse.data.ratings]);
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token]);

  const { contentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryContentType = searchParams.get("content-type");

  useEffect(() => {
    (async () => {
      if (mode === "edit") {
        const content = await GetReq(
          `content/${contentId}?contentType=${queryContentType}`,
          true,
          token
        );
        console.log({ content });
      }
    })();
  }, [mode, token, contentId, queryContentType]);

  const initialState = {
    en: {
      title: "",
      plot: "",
    },
    sp: {
      title: "",
      plot: "",
    },
    poster: null,
    duration: 0,
    contentType: "",
    languages: [],
    artist: [],
    producers: [],
    directors: [],
    genres: [],
    technicians: [],
    trailer: null,
    rating: "",
    releaseDate: "",
    status: true,
    planType: "",
    url: "",
    homePage: false,
    slider: false,
    priority: null,
  };

  const handleChangeArtistName = (index, event) => {
    const data = [...selectedArtists];
    data[index]["name"] = event.target.value;
    setSelectedArtists([...data]);
  };

  const handleArtistSelect = (event, index) => {
    const data = [...selectedArtists];
    data[index]["id"] = { ...event };
    setSelectedArtists([...data]);
  };

  const handleArtistRemove = (event, index) => {
    const data = [...selectedArtists];
    data[index]["id"] = { ...event };
    setSelectedArtists([...data]);
  };

  const addArtistHandler = () => {
    setSelectedArtists([...selectedArtists, { id: null, name: "" }]);
  };

  const renderStepContent = (
    step,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    touched
  ) => {
    switch (step) {
      case 0:
        return (
          <MultiLangForm
            value={value}
            tabChangeHandler={tabChangeHandler}
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            rating={rating}
            formCount={formCount}
            setFormCount={setFormCount}
            setFieldValue={setFieldValue}
            multiSelectStyle={multiSelectStyle}
            genres={genres}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
            errorMessage={errorMessage}
            // errorField={errorField}
          />
        );
      case 1:
        return (
          <DetailForm
            posterInputRef={posterInputRef}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            multiSelectStyle={multiSelectStyle}
            languages={languages}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
            trailerInputRef={trailerInputRef}
            genres={genres}
            formCount={formCount}
            setFormCount={setFormCount}
            errorMessage={errorMessage}
            // errorField={errorField}
          />
        );
      case 2:
        return (
          <CastForm
            handleSelect={handleSelect}
            handleRemove={handleRemove}
            values={values}
            multiSelectStyle={multiSelectStyle}
            producers={producers}
            directors={directors}
            selectedArtists={selectedArtists}
            handleArtistSelect={handleArtistSelect}
            handleArtistRemove={handleArtistRemove}
            artists={artists}
            handleChangeArtistName={handleChangeArtistName}
            handleBlur={handleBlur}
            technicians={technicians}
            addArtistHandler={addArtistHandler}
            formCount={formCount}
            setFormCount={setFormCount}
            errorMessage={errorMessage}
            // errorField={errorField}
          />
        );
      default:
        <>Not Found</>;
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={8}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  Add Content
                  {/* {mode === "add" ? "Add Template" : "Edit Template"} */}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  // validationSchema={Yup.object().shape({
                  //   // "en.title": Yup.string().required("Title is required."),
                  //   genre: Yup.string().required("Role is required."),
                  // })}
                  onSubmit={async (values) => {
                    const formattedData = { ...values };
                    formattedData.releaseDate =
                      formattedData.releaseDate.$d.toISOString();

                    formattedData.en = JSON.stringify(formattedData.en);
                    formattedData.sp = JSON.stringify(formattedData.sp);
                    if (
                      selectedArtists &&
                      selectedArtists.length > 0 &&
                      selectedArtists[0].id
                    ) {
                      formattedData.artist = JSON.stringify(
                        selectedArtists.map((artist) => ({
                          id: artist.id[0]._id,
                          charName: artist.name,
                        }))
                      );
                    }

                    if (formattedData.directors.length > 0) {
                      formattedData.directors = JSON.stringify(
                        formattedData.directors.map((director) => director._id)
                      );
                    }
                    if (formattedData.genres.length > 0) {
                      formattedData.genre = JSON.stringify(
                        formattedData.genres.map((genre) => genre._id)
                      );
                      delete formattedData.genres;
                    }
                    if (formattedData.producers.length > 0) {
                      formattedData.producers = JSON.stringify(
                        formattedData.producers.map((producer) => producer._id)
                      );
                    }
                    if (formattedData.technicians.length > 0) {
                      formattedData.technicians = JSON.stringify(
                        formattedData.technicians.map(
                          (technician) => technician._id
                        )
                      );
                    }

                    if (formattedData.languages.length > 0) {
                      formattedData.language = JSON.stringify(
                        formattedData.languages.map((language) => language.name)
                      );
                      delete formattedData.languages;
                    }

                    formattedData.contentRating = formattedData.rating;
                    delete formattedData.rating;

                    formattedData.archived = !formattedData.status;
                    delete formattedData.status;

                    try {
                      const response = await PostReq(
                        `content-upload`,
                        formattedData,
                        true,
                        "multipart/form-data",
                        token
                      );
                      if (response) {
                        const successMessage = response.data.message;
                        toast.remove();
                        toast.success(successMessage);
                        setLoader(false);
                        navigate("/content", { replace: true });
                      }
                    } catch (error) {
                      errorHandler(error);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftBox>
                        {renderStepContent(
                          formCount,
                          values,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          errors,
                          touched
                        )}
                        <FormButtons
                          formCount={formCount}
                          setFormCount={setFormCount}
                          values={values}
                          genres={genres}
                          setErrorMessage={setErrorMessage}
                          // setErrorField={setErrorField}
                        />
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CommonForm;
