import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GetReq } from "lib/requests";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "examples/Tables/Table";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { DeleteReq } from "lib/requests";
import { toast } from "react-hot-toast";
import { errorHandler } from "hooks/methods";
import CustomDialog from "components/Dialog";
import Loader from "components/Loader";
import SoftBadge from "components/SoftBadge";
import CustomCard from "components/CustomCard";
import SoftInput from "components/SoftInput";
import SearchIcon from "@mui/icons-material/Search";
import { IMAGE_BASE_URL } from "constants";

const Talent = () => {
  const { token } = useSelector((state) => state.user);

  const [talentList, setTalentList] = useState([]);
  const [talentCount, setTalentCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [currentTalent, setCurrentTalent] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortOn, setSortOn] = useState("createdAt");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(
          `get-profiles?role=talent&page=${page}&limit=${10}&sort=${sortOn}&search=${search}`,
          true,
          token
        );
        setTalentList(response.data.profiles);
        setTalentCount(response.data.profileCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token, page]);

  // useEffect(() => {
  //   (async () => {})();
  // }, [sortOn, search]);

  const handleChange = async (event, value) => {
    setPage(value);
    try {
      const response = await GetReq(
        `get-profiles?role=talent&page=${value}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setTalentList(response.data.profiles);
      setTalentCount(response.data.profileCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);

    try {
      const response = await GetReq(
        `get-profiles?role=talent&page=${page}&limit=${10}&sort=${sortOn}&search=${
          event.target.value
        }`,
        true,
        token
      );
      setTalentList(response.data.profiles);
      setTalentCount(response.data.profileCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteHandler = async (talentId) => {
    setCurrentTalent(talentId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmationHandler = async () => {
    setOpen(false);
    setLoader(true);
    try {
      console.log(currentTalent);
      const response = await DeleteReq(`profile/${currentTalent}`, true, token);
      toast.remove();
      setTalentList((prevState) =>
        prevState.filter((talent) => talent._id !== currentTalent)
      );
      toast.success(response.data.message);
      let pageValue = page;
      if (talentCount % 10 === 1 && talentCount !== 1) {
        setPage(page - 1);
        pageValue -= 1;
      }
      const userResponse = await GetReq(
        `get-profiles?role=talent&page=${pageValue}&limit=${10}&sort=${sortOn}&search=${search}`,
        true,
        token
      );
      setTalentList(userResponse.data.profiles);
      setTalentCount(userResponse.data.profileCount);
      setLoader(false);
    } catch (error) {
      errorHandler(error);
      setLoader(false);
    }
  };

  function Profile({ banner, name }) {
    console.log({ banner });
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar
            src={`${IMAGE_BASE_URL}${banner}`}
            alt={name}
            size="sm"
            variant="rounded"
          />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const columns = [
    { name: "talent", align: "left", sort: true },
    { name: "role", align: "left", sort: true },
    { name: "profile description", align: "center", sort: false },
    //   { name: "function", align: "left" },
    //   { name: "status", align: "center" },
    { name: "account created", align: "center", sort: true },
    { name: "status", align: "center", sort: true },
    { name: "action", align: "center", sort: false },
  ];

  const rows = [];

  const handleSort = async (name) => {
    let key = "cratedAt";
    if (name === "talent") {
      if (sortOn === "name" && name[0] !== "-") {
        setSortOn(() => "-name");
        key = "-name";
      } else {
        setSortOn(() => "name");
        key = "name";
      }
    } else if (name === "account created" && name[0] !== "-") {
      if (sortOn === "createdAt") {
        setSortOn(() => "-createdAt");
        key = "-createdAt";
      } else {
        setSortOn(() => "createdAt");
        key = "createdAt";
      }
    } else if (name === "status" && name[0] !== "-") {
      if (sortOn === "archived") {
        setSortOn(() => "-archived");
        key = "-archived";
      } else {
        setSortOn(() => "archived");
        key = "archived";
      }
    } else if (name === "role" && name[0] !== "-") {
      if (sortOn === "role") {
        setSortOn(() => "-role");
        key = "-role";
      } else {
        setSortOn(() => "role");
        key = "role";
      }
    }

    try {
      const response = await GetReq(
        `get-profiles?role=talent&page=${page}&limit=${10}&sort=${key}&search=${search}`,
        true,
        token
      );
      setTalentList(response.data.profiles);
      setTalentCount(response.data.profileCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  const pageCount = Math.ceil(talentCount / 10);

  for (const row of talentList) {
    rows.push({
      talent: <Profile banner={row.banner} name={row.name} />,
      role: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.role}
        </SoftTypography>
      ),
      "account created": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {new Date(row.createdAt).getDate()} /
          {new Date(row.createdAt).getMonth()} /
          {new Date(row.createdAt).getFullYear()}
        </SoftTypography>
      ),
      "profile description": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {row.translations?.en?.profile.length > 20
            ? row.translations?.en?.profile.slice(0, 21) + "..."
            : row.translations?.en?.profile}
        </SoftTypography>
      ),
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent={row.archived === false ? "active" : "Inactive"}
          color={row.archived === false ? "success" : "error"}
          size="xs"
          container
        />
      ),
      action: (
        <>
          <Link to={`/edit-talent/${row._id}`}>
            <SoftButton
              color="info"
              variant="gradient"
              // onClick={handleWhiteSidenav}
              // disabled={disabled}
              // sx={sidenavTypeButtonsStyles}
              sx={{ marginRight: "10px" }}
            >
              Edit
            </SoftButton>
          </Link>
          <SoftButton
            color="error"
            variant="gradient"
            sx={{ marginLeft: "10px" }}
            onClick={() => deleteHandler(row._id)}
            // disabled={disabled}
            // sx={sidenavTypeButtonsStyles}
          >
            Delete
          </SoftButton>
        </>
      ),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loader && <Loader />}
      {!loader && (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                {/* <Stack direction="row" alignItems="center"> */}
                <SoftInput
                  name="search"
                  id="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search Talent"
                />
                {/* <button
                    style={{
                      padding: "5px 30px",
                      cursor: "pointer",
                      background: "white",
                      border: "0.0625rem solid #d2d6da",
                      borderRadius: "5px",
                    }}
                    type="button"
                    onClick={handleSearch}
                  >
                    <Icon
                      fontSize="30px"
                      sx={{ color: "#d2d6da !important" }}
                      color="#d2d6da !important"
                    >
                      search
                    </Icon>
                  </button> */}
                {/* </Stack> */}
              </Grid>
              <SoftButton
                color="info"
                variant="gradient"
                onClick={() => navigate("/add-talent")}
                // disabled={disabled}
                // sx={sidenavTypeButtonsStyles}
              >
                Add Talent
              </SoftButton>
            </Grid>
          </SoftBox>

          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Talent table</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {!loader && (
                <Table
                  columns={columns}
                  rows={rows}
                  docCount={talentCount}
                  handleSort={handleSort}
                  message="No talent exists."
                />
              )}
              <CustomDialog
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
                confirmationHandler={confirmationHandler}
                title="Are you sure?"
                content="You want to delete this talent?"
                loader={loader}
              />
            </SoftBox>
          </Card>

          {pageCount > 1 && (
            <Card
              sx={{
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
            </Card>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Talent;
