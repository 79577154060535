import { Card } from '@mui/material'
import Loader from 'components/Loader'
import SoftBox from 'components/SoftBox'
import SoftButton from 'components/SoftButton'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import CKEditorComponent from 'examples/CKEditor'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Formik } from 'formik'
import { errorHandler } from 'hooks/methods'
import { GetReq } from 'lib/requests'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import cmsPageSchema from '../schema/cmsPageSchema'
import { PatchReq } from 'lib/requests'
import { toast } from 'react-hot-toast'
const CMSForm = () => {

    const { cmsId } = useParams();

    const [loader, setLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState({});
    const [data, setData] = useState("");

    const navigate = useNavigate();

    const { token } = useSelector(state => state.user);

    useEffect(() => {
        (async () => {
            setLoader(true)
            try {
                const response = await GetReq(`get-cms-page/${cmsId}`, true, token);
                if (response) {
                    setCurrentPage(response.data.CMSPage)
                    setData(response.data.CMSPage.content)
                    setLoader(false)
                }
            } catch (error) {
                errorHandler(error);
                setLoader(false)

            }
        })()
    }, [token, cmsId])

    const initialState = {
        title: currentPage.title,
    }


    const submitHandler = async (values) => {
        try {
            const response = await PatchReq(`update-cms-page/${cmsId}`, { title: values.title, content: data }, true, "json", token);
            if (response) {
                toast.success(response.data.message)
                setLoader(false);
                navigate("/cms")
            }
        } catch (error) {
            errorHandler(error);
            setLoader(false)
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                loader && <Loader />
            }
            {
                !loader && (
                    <SoftBox p={3}>
                        <Card sx={{ padding: "10px", overflow: "visible" }}>

                            <SoftBox p={3} mb={1} textAlign="center">
                                <SoftTypography variant="h5" fontWeight="medium">
                                    Edit Page
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox p={3} mb={1}>
                                <Formik
                                    initialValues={initialState}
                                    validationSchema={cmsPageSchema}
                                    onSubmit={(values) => submitHandler(values)}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <SoftBox>
                                                <SoftBox mb={2}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                        >
                                                            Title
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        name="title"
                                                        id="title"
                                                        value={values.title}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        placeholder="Title"
                                                    />
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        color="error"
                                                    >
                                                        {errors.title && touched.title && errors.title}
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftBox mb={2}>
                                                    <CKEditorComponent data={data} setData={setData} />
                                                </SoftBox>

                                                <SoftBox mt={4} mb={1}>
                                                    <SoftButton
                                                        type="submit"
                                                        variant="gradient"
                                                        color="info"
                                                        fullWidth
                                                    >
                                                        Edit Page
                                                    </SoftButton>
                                                </SoftBox>
                                            </SoftBox>
                                        </form>
                                    )}
                                </Formik>
                            </SoftBox>
                        </Card>
                    </SoftBox>
                )
            }

        </DashboardLayout>

    )
}

export default CMSForm