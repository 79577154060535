import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
// import ChangePasswordSchema from "../schema/ChangePasswordSchema";
import { PostReq } from "lib/requests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import { PatchReq } from "lib/requests";
import { errorHandler } from "hooks/methods";
import changePasswordSchema from "../schema/changePasswordSchema";

function ChangePassword() {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.user);

  const initialState = {
    previousPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const submitHandler = async (values) => {
    try {
      const response = await PatchReq(
        "change-password",
        values,
        true,
        "json",
        token
      );
      if (response) {
        // dispatch(
        //   login({ user: response.data.user, token: response.data.token })
        // );
        const successMessage = response.data.message;
        toast.remove();
        toast.success(successMessage);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <CoverLayout
      title="Change Password"
      description="Enter your new password"
      image={curved9}
    >
      <Formik
        initialValues={initialState}
        validationSchema={changePasswordSchema}
        onSubmit={(values) => submitHandler(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <SoftBox>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Previous Password
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="previousPassword"
                  id="previousPassword"
                  value={values.previousPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  placeholder="Previous Password"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errors.previousPassword &&
                    touched.previousPassword &&
                    errors.previousPassword}
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    New Password
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="newPassword"
                  id="newPassword"
                  value={values.newPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  placeholder="New Password"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errors.newPassword &&
                    touched.newPassword &&
                    errors.newPassword}
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Confirm Password
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="confirmPassword"
                  id="confirmPassword"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  placeholder="Confirm Password"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword}
                </SoftTypography>
              </SoftBox>

              <SoftBox mt={4} mb={1}>
                <SoftButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  Change password
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </form>
        )}
      </Formik>
    </CoverLayout>
  );
}

export default ChangePassword;
