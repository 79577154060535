import { useState } from "react";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import reset from "assets/images/reset.jpg";
import signInSchema from "../schema/signInSchema";
import { PostReq } from "lib/requests";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import { PatchReq } from "lib/requests";
import resetPasswordSchema from "../schema/resetPasswordSchema";

function SignIn() {
  const { resetToken } = useParams();

  const navigate = useNavigate();

  const initialState = {
    password: "",
    confirmPassword: "",
  };

  const submitHandler = async (values) => {
    try {
      const response = await PatchReq(
        `reset-password/${resetToken}`,
        values,
        false,
        "json"
      );
      if (response) {
        const successMessage = response.data.message;
        toast.remove();
        toast.success(successMessage);
        navigate("/login");
      }
    } catch (error) {
      const errorMessage = error.response.data.message || error.message;
      toast.remove();
      toast.error(errorMessage);
    }
  };

  return (
    <CoverLayout
      title="Reset password"
      description="Enter your new password."
      image={reset}
    >
      <Formik
        initialValues={initialState}
        validationSchema={resetPasswordSchema}
        onSubmit={(values) => submitHandler(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <SoftBox>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Password
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="password"
                  id="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  placeholder="Password"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errors.password && touched.password && errors.password}
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Confirm Password
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="confirmPassword"
                  id="confirmPassword"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  placeholder="Confirm Password"
                />
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword}
                </SoftTypography>
              </SoftBox>
              {/* <SoftBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;&nbsp;Remember me
                </SoftTypography>
              </SoftBox> */}

              <SoftBox mt={4} mb={1}>
                <SoftButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  Reset Password
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </form>
        )}
      </Formik>
    </CoverLayout>
  );
}

export default SignIn;
