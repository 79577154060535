import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const nameRegExp =
  /(^[A-Za-z]{1,16})([ ]{0,1})([A-Za-z]{1,16})?([ ]{0,1})?([A-Za-z]{1,16})?([ ]{0,1})?([A-Za-z]{1,16})/;

export const addValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required."),
  name: Yup.string()
    .required("Email is required.")
    .min(2, "name must contain at least two characters")
    .max(30, "name can be contain thirty characters")
    .matches(nameRegExp, "Name must be valid"),
  phoneNumber: Yup.string()
    .required("Mobile is required")
    .min(7, "Number must contain at least seven length")
    .max(15, "Number must contain at least fifteen length")
    .matches(phoneRegExp, "Mobile must be valid"),
  password: Yup.string().required("Password is required."),
});

export const editValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required."),
  name: Yup.string()
    .required("Name is required.")
    .min(2, "name must contain at least two characters")
    .max(30, "name can be contain thirty characters")
    .matches(nameRegExp, "Name must be valid"),
  phoneNumber: Yup.string()
    .required("Mobile is required")
    .min(7, "Number must contain at least seven length")
    .max(15, "Number must contain at least fifteen length")
    .matches(phoneRegExp, "Mobile must be valid"),
});
