import { Card, Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";

const NotFound = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid
        container
        display="flex"
        justifyContent="center"
        height="80vh"
        alignItems="center"
      >
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Card sx={{ padding: "50px" }}>
            <SoftTypography
              sx={{ fontSize: "15rem", fontWeight: "100", margin: "0" }}
            >
              404
            </SoftTypography>
            <SoftTypography textTransform="uppercase">
              The page you requested could not be found
            </SoftTypography>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default NotFound;
