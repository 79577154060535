import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
// import signInSchema from "../schema/signInSchema";
import { PostReq } from "lib/requests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import {
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import Loader from "components/Loader";
import { PatchReq } from "lib/requests";
import ImagePreview from "components/ImagePreview";
import * as Yup from "yup";

function TalentForm({ mode }) {
  const { token } = useSelector((state) => state.user);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(0);

  const { profileId } = useParams();

  const bannerInputRef = useRef();

  const initialState = {
    name: "",
    banner: "",
    active: true,
    en: {
      profile: "",
    },
    sp: {
      profile: "",
    },
    role: "",
    image: "",
  };

  // useEffect(() => {
  //   if (mode === "edit") {
  //     (async () => {
  //       try {
  //         loader(true);
  //         const response = GetReq(`profile/${profileId}`, true, token);
  //         if (response) {
  //           initialState.name = response.data.profile.name;
  //           initialState.banner = response.data.profile.banner;
  //           initialState.active = !response.data.profile.archived;
  //           initialState.profile = response.data.profile.profile;
  //           initialState.role = response.data.profile.role;
  //           loader(false);
  //         } else {
  //           loader(false);
  //         }
  //       } catch (error) {
  //         loader(false);
  //         errorHandler(error);
  //       }
  //     })();
  //   }
  // }, [profileId, token, loader, mode]);

  const navigate = useNavigate();

  useEffect(() => {
    if (mode.toLowerCase() === "edit") {
      (async () => {
        setLoader(true);
        try {
          const response = await GetReq(`profile/${profileId}`, true, token);
          if (response) {
            setCurrentProfile(response.data.profile);
          }
          setLoader(false);
        } catch (error) {
          errorHandler(error);
          setLoader(false);
        }
      })();
    }
  }, [token, mode, profileId]);

  const submitHandler = async (values) => {
    if (mode.toLowerCase() === "add") {
      try {
        setLoader(true);
        const { active, ...restValues } = values;

        const formData = new FormData();

        restValues.archived = !active;

        formData.append("name", restValues.name);
        formData.append("role", restValues.role);
        formData.append("banner", restValues.banner);
        formData.append("en", JSON.stringify(restValues.en));
        formData.append("sp", JSON.stringify(restValues.sp));
        formData.append("archived", restValues.archived);

        const response = await PostReq(
          "add-profile",
          formData,
          true,
          "multipart/form-data",
          token
        );
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/talent", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    } else if (mode.toLowerCase() === "edit") {
      try {
        setLoader(true);
        const finalValues = { ...values, archived: !values.active };
        const formData = new FormData();

        formData.append("name", finalValues.name);
        formData.append("role", finalValues.role);
        if (finalValues.banner) formData.append("banner", finalValues.banner);
        formData.append("en", JSON.stringify(finalValues.en));
        formData.append("sp", JSON.stringify(finalValues.sp));
        formData.append("archived", finalValues.archived);

        const response = await PatchReq(
          `profile/${profileId}`,
          formData,
          true,
          "multipart/form-data",
          token
        );
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/talent", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    }
  };

  if (currentProfile) {
    initialState.name = currentProfile.name;
    initialState.en = currentProfile.translations.en;
    initialState.sp = currentProfile.translations.sp;
    initialState.image = currentProfile.banner;
    initialState.role = currentProfile.role;
    initialState.active = !currentProfile.archived;
  }

  const nameRegExp =
    /(^[A-Za-z]{1,16})([ ]{0,1})([A-Za-z]{1,16})?([ ]{0,1})?([A-Za-z]{1,16})?([ ]{0,1})?([A-Za-z]{1,16})/;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Grid
          container
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  {mode === "add" ? "Add Talent" : "Edit Talent"}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .required("Name is required.")
                      .matches(nameRegExp, "Name must be valid"),
                    role: Yup.string().required("Role is required."),
                  })}
                  onSubmit={(values) => submitHandler(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftBox>
                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Name
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="name"
                            id="name"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            placeholder="Name"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.name && touched.name && errors.name}
                          </SoftTypography>
                        </SoftBox>

                        <Grid item xs={10}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Banner
                                  </SoftTypography>
                                </SoftBox>
                                <input
                                  name="banner"
                                  id="banner"
                                  hidden
                                  ref={bannerInputRef}
                                  //   value={values.type}
                                  onBlur={handleBlur}
                                  //   disabled={mode === "edit"}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "banner",
                                      event.target.files[0]
                                    );
                                  }}
                                  type="file"
                                  placeholder="Banner"
                                />
                                <SoftButton
                                  type="button"
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  onClick={() => bannerInputRef.current.click()}
                                >
                                  Add Banner
                                </SoftButton>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {/* {errors.email && touched.email && errors.email} */}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                            {(values.banner || values.image) && (
                              <ImagePreview
                                image={values.image}
                                file={values.banner}
                              />
                            )}
                          </Stack>
                        </Grid>

                        <SoftBox mb={2}>
                          <Grid item xs={12} mb={2}>
                            <Stack
                              justifyContent="center"
                              alignItems="center"
                              direction="row"
                            >
                              <Grid item xs={6}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Profile Description
                                  </SoftTypography>
                                </SoftBox>
                              </Grid>
                              <Grid item xs={6}>
                                <Card
                                  sx={{ padding: "5px" }}
                                  style={{ backgroundColor: "#f0f1f2" }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item xs={6}>
                                      <SoftButton
                                        type="button"
                                        onClick={() => setValue(0)}
                                        disabled={value === 0}
                                        fullWidth
                                        sx={{ padding: "1px !important" }}
                                      >
                                        English
                                      </SoftButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <SoftButton
                                        onClick={() => setValue(1)}
                                        type="button"
                                        disabled={value === 1}
                                        fullWidth
                                      >
                                        Spainesh
                                      </SoftButton>
                                    </Grid>
                                  </Stack>
                                </Card>
                              </Grid>
                            </Stack>
                          </Grid>
                          {value === 0 && (
                            <div
                              style={{
                                border: "0.0625rem solid #d2d6da",
                                padding: "8px 12px",
                                minWidth: "90%",
                                borderRadius: "0.5rem",
                                cursor: "text",
                              }}
                            >
                              {console.log({ values })}
                              <textarea
                                value={values.en.profile}
                                placeholder="Profile Description"
                                onChange={handleChange}
                                id="en.profile"
                                name="en.profile"
                                style={{
                                  maxWidth: "100%",
                                  minWidth: "100%",
                                  border: "none",
                                  outline: "none",
                                }}
                              ></textarea>
                            </div>
                          )}
                          {value === 1 && (
                            <div
                              style={{
                                border: "0.0625rem solid #d2d6da",
                                padding: "8px 12px",
                                minWidth: "90%",
                                borderRadius: "0.5rem",
                                cursor: "text",
                              }}
                            >
                              <textarea
                                value={values.sp.profile}
                                placeholder="Profile Description"
                                onChange={handleChange}
                                id="sp.profile"
                                name="sp.profile"
                                style={{
                                  maxWidth: "100%",
                                  minWidth: "100%",
                                  border: "none",
                                  outline: "none",
                                }}
                              ></textarea>
                            </div>
                          )}
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {/* {errors.email && touched.email && errors.email} */}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Role
                            </SoftTypography>
                          </SoftBox>
                          <FormControl sx={{ width: "100% !important" }}>
                            <Select
                              value={values.role}
                              name="role"
                              onChange={handleChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              sx={{
                                paddingLeft: "0px !important",
                              }}
                            >
                              <MenuItem id="role" value="">
                                None
                              </MenuItem>
                              <MenuItem id="role" value="artist">
                                Artist
                              </MenuItem>
                              <MenuItem id="role" value="producer">
                                Producer
                              </MenuItem>
                              <MenuItem id="role" value="director">
                                Director
                              </MenuItem>
                              <MenuItem id="role" value="technician">
                                Technician
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {errors.role && touched.role && errors.role}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox mt={3} mb={2} lineHeight={1}>
                          <SoftTypography variant="h6">Status</SoftTypography>

                          <Switch
                            id="active"
                            name="active"
                            checked={values.active}
                            onChange={handleChange}
                            value={values.active}
                          />
                        </SoftBox>

                        <SoftBox mt={4} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {mode === "add" ? "Add Talent" : "Edit Talent"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default TalentForm;
