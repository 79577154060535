import {
  Card,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";

const MultiLangForm = ({
  handleBlur,
  handleChange,
  values,
  rating,
  formCount,
  setFieldValue,
  setFormCount,
  multiSelectStyle,
  handleSelect,
  handleRemove,
  genres,
  errors,
  touched,
  errorMessage,
  errorField,
}) => {
  const [value, setValue] = useState(0);

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ padding: "5px" }} style={{ backgroundColor: "#f0f1f2" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sx={{ backgroundColor: "black" }}>
              <SoftButton
                type="button"
                onClick={() => setValue(0)}
                disabled={value === 0}
                fullWidth
              >
                English
              </SoftButton>
            </Grid>
            <Grid item xs={6} sx={{ backgroundColor: "black" }}>
              <SoftButton
                onClick={() => setValue(1)}
                type="button"
                disabled={value === 1}
                fullWidth
              >
                Spainesh
              </SoftButton>
            </Grid>
          </Stack>
        </Card>
      </Grid>
      {value === 0 && (
        <>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Title
              </SoftTypography>
            </SoftBox>
            <SoftInput
              name="en.title"
              id="en.title"
              value={values.en.title}
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue("en.title", event.target.value);
              }}
              type="text"
              placeholder="Title"
            />
            {errorMessage && errorMessage.title && (
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="error"
              >
                {errorMessage.title}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Plot
              </SoftTypography>
            </SoftBox>
            <div
              style={{
                border: "0.0625rem solid #d2d6da",
                padding: "8px 12px",
                minWidth: "90%",
                borderRadius: "0.5rem",
                cursor: "text",
              }}
            >
              <textarea
                id="en.plot"
                name="en.plot"
                placeholder="Plot"
                value={values.en.plot}
                onChange={handleChange}
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  border: "none",
                  outline: "none",
                }}
              ></textarea>
            </div>
            {errorMessage && errorMessage.plot && (
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="error"
              >
                {errorMessage.plot}
              </SoftTypography>
            )}
          </SoftBox>
        </>
      )}

      {value === 1 && (
        <>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Título
              </SoftTypography>
            </SoftBox>
            <SoftInput
              name="sp.title"
              id="sp.title"
              value={values.sp.title}
              onBlur={handleBlur}
              //   disabled={mode === "edit"}
              onChange={handleChange}
              type="text"
              placeholder="Título"
            />

            {errorMessage && errorMessage.title && (
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="error"
              >
                {errorMessage.title}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Trama
              </SoftTypography>
            </SoftBox>
            <div
              style={{
                border: "0.0625rem solid #d2d6da",
                padding: "8px 12px",
                minWidth: "90%",
                borderRadius: "0.5rem",
                cursor: "text",
              }}
            >
              <textarea
                id="sp.plot"
                name="sp.plot"
                placeholder="Trama"
                value={values.sp.plot}
                onChange={handleChange}
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  border: "none",
                  outline: "none",
                }}
              ></textarea>
            </div>
            {errorMessage && errorMessage.plot && (
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="error"
              >
                {errorMessage.plot}
              </SoftTypography>
            )}
          </SoftBox>
        </>
      )}
      <Divider />
      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Genre
          </SoftTypography>
        </SoftBox>
        <Multiselect
          style={multiSelectStyle}
          className="form-control"
          options={genres} // Options to display in the dropdown
          displayValue="title"
          onSelect={(event) => handleSelect(event, values, "genres")}
          onRemove={(event) => handleRemove(event, values, "genres")}
          // selectionLimit={1}
          // style={{}}
          onBlur={handleBlur}
          id="genre"
          name="genre"
          closeOnSelect
          selectedValues={values.genres}
          // singleSelect={true}
        />
        {errorMessage && errorMessage.genre && (
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="error"
          >
            {errorMessage.genre}
          </SoftTypography>
        )}
      </SoftBox>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Type
                </SoftTypography>
              </SoftBox>
              <FormControl sx={{ width: "100% !important" }}>
                <Select
                  value={values.contentType}
                  name="contentType"
                  id="contentType"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    paddingLeft: "0px !important",
                  }}
                >
                  <MenuItem id="contentType" value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem id="contentType" value="movie">
                    Movie
                  </MenuItem>
                  <MenuItem id="contentType" value="series">
                    Series
                  </MenuItem>
                  {/* <MenuItem id="contentType" value="episode">
                                Episode
                              </MenuItem> */}
                </Select>
              </FormControl>
              {errorMessage && errorMessage.contentType && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.contentType}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          <Grid item xs={5}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Rating
                </SoftTypography>
              </SoftBox>
              <FormControl sx={{ width: "100% !important" }}>
                <Select
                  value={values.rating}
                  name="rating"
                  id="rating"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    paddingLeft: "0px !important",
                  }}
                >
                  <MenuItem id="rating" value="">
                    <em>None</em>
                  </MenuItem>
                  {rating.map((rating) => (
                    <MenuItem id="rating" value={rating._id}>
                      {rating.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorMessage && errorMessage.rating && (
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="error"
                >
                  {errorMessage.rating}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
};

export default MultiLangForm;
