import { Card, Divider, Grid, Switch, Tab, Tabs } from "@mui/material";
import Loader from "components/Loader";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Formik } from "formik";
import { errorHandler } from "hooks/methods";
import { PatchReq } from "lib/requests";
import { GetReq } from "lib/requests";
import { PostReq } from "lib/requests";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

const CategoryForm = ({ mode }) => {
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(0);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [currentGenre, setCurrentGenre] = useState(null);

  const navigate = useNavigate();

  const { token } = useSelector((state) => state.user);
  const { genreId } = useParams();

  const initialState = {
    en: {
      title: "",
    },
    sp: {
      title: "",
    },
    status: true,
  };

  useEffect(() => {
    if (mode === "edit") {
      setLoader(true);
      (async () => {
        try {
          const response = await GetReq(`genre/${genreId}`, true, token);
          setCurrentGenre(response.data.data);
          setLoader(false);
        } catch (error) {
          setLoader(false);
          errorHandler(error);
        }
      })();
    }
  }, [token, mode, genreId]);

  const submitHandler = async (values) => {
    try {
      if (
        !errorSubmit ||
        (values.en?.title?.trim()?.length > 0 &&
          values.sp?.title?.trim()?.length > 0)
      ) {
        if (mode === "add") {
          const response = await PostReq(`genre`, values, true, "json", token);
          if (response) {
            const successMessage = response.data.message;
            toast.remove();
            toast.success(successMessage);
            setLoader(false);
            navigate("/genre", { replace: true });
          }
        } else if (mode === "edit") {
          const response = await PatchReq(
            `genre/${genreId}`,
            values,
            true,
            "json",
            token
          );
          if (response) {
            const successMessage = response.data.message;
            toast.remove();
            toast.success(successMessage);
            setLoader(false);
            navigate("/genre", { replace: true });
          }
        }
      } else {
        return;
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  if (currentGenre) {
    initialState.en = currentGenre.translations.en;
    initialState.sp = currentGenre.translations.sp;
    initialState.status = !currentGenre.archived;
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  const tabChangeHandler = (event, newValue) => {
    setValue(newValue);
  };

  const errorHandler = (status) => {
    setErrorSubmit(status);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Grid
          container
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  {mode === "add" ? "Add Genre" : "Edit Genre"}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  // validationSchema={
                  //   Yup.object().shape({
                  //     "en.title": Yup.string()
                  //       .required("English title is required."),
                  //     "sp.title": Yup.string()
                  //       .required("Se requiere titulo español.")

                  //   })
                  // }
                  onSubmit={(values) => submitHandler(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="error"
                      ></SoftTypography>
                      <SoftBox>
                        <Tabs
                          value={value}
                          onChange={tabChangeHandler}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="scrollable force tabs example"
                        >
                          <Tab label="English" />
                          <Tab label="Spainesh" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Title
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              name="en.title"
                              id="en.title"
                              value={values.en.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              placeholder="Enter title"
                            />
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="error"
                            >
                              {console.log(errors)}
                              {errors["en.title"] &&
                                touched.en.title &&
                                errors["en.title"]}
                            </SoftTypography>
                          </SoftBox>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Título
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              name="sp.title"
                              id="sp.title"
                              value={values.sp.title}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              placeholder="Introduce el título"
                            />
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="error"
                            >
                              {errors["sp.title"] &&
                                touched.sp.title &&
                                errors["sp.title"]}
                            </SoftTypography>
                          </SoftBox>
                        </TabPanel>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          color="error"
                        >
                          {(values.en?.title?.trim()?.length === 0 &&
                            touched.en?.title) ||
                          (values.sp?.title?.trim()?.length === 0 &&
                            touched.sp?.title)
                            ? errorHandler(true)
                            : errorHandler(false)}
                          {errorSubmit && "Please fill both title fields."}
                        </SoftTypography>
                        <Divider />

                        <SoftBox mt={3} mb={2} lineHeight={1}>
                          <SoftTypography variant="h6">Status</SoftTypography>
                          <Switch
                            id="status"
                            name="status"
                            checked={values.status}
                            onChange={handleChange}
                            value={values.status}
                          />
                        </SoftBox>

                        <SoftBox mt={4} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {mode === "add" ? "Add Genre" : "Edit Genre"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default CategoryForm;
