import React from "react";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Grid
      container
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="info" />
    </Grid>
  );
};

export default Loader;
