import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
// import signInSchema from "../schema/signInSchema";
import { PostReq } from "lib/requests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { login } from "store/reducers/userSlice";
import {
  Card,
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { errorHandler } from "hooks/methods";
import { GetReq } from "lib/requests";
import Loader from "components/Loader";
import { PatchReq } from "lib/requests";
import ImagePreview from "components/ImagePreview";

function SubscriptionForm({ mode }) {
  const { token } = useSelector((state) => state.user);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState(0);
  const [descriptionEN, setDescriptionEN] = useState([{ name: "" }]);
  const [descriptionSP, setDescriptionSP] = useState([{ name: "" }]);
  const [coupons, setCoupons] = useState([]);

  const { profileId } = useParams();

  const handleChangeSubsDescriptionEN = (index, event) => {
    const data = [...descriptionEN];
    data[index]["name"] = event.target.value;
    setDescriptionEN([...data]);
  };

  const addDescriptionHandlerEN = () => {
    setDescriptionEN([...descriptionEN, { name: "" }]);
  };

  const handleChangeSubsDescriptionSP = (index, event) => {
    const data = [...descriptionSP];
    data[index]["name"] = event.target.value;
    setDescriptionSP([...data]);
  };

  const addDescriptionHandlerSP = () => {
    setDescriptionSP([...descriptionSP, { name: "" }]);
  };

  const initialState = {
    planTitle: "",
    planPrice: 0,
    planDuration: "",
    en: {
      description: [],
    },
    sp: {
      description: [],
    },
    subscriptionType: "",
    couponId: "",
    active: "",
  };

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const response = await GetReq(`coupons`, true, token);
        setCoupons(response.data.coupons);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        errorHandler(error);
      }
    })();
  }, [token]);

  const submitHandler = async (values) => {
    if (mode.toLowerCase() === "add") {
      try {
        setLoader(true);
        // const { active, ...restValues } = values;
        values.archived = !values.active;
        delete values.active;
        values.en.description = descriptionEN.map(
          (_description) => _description.name
        );
        values.sp.description = descriptionSP.map(
          (_description) => _description.name
        );

        const response = await PostReq(
          "subscription-plan",
          values,
          true,
          "json",
          token
        );
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/subscription", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    } else if (mode.toLowerCase() === "edit") {
      try {
        setLoader(true);
        const finalValues = { ...values, archived: !values.active };

        const response = await PatchReq(
          `profile/${profileId}`,
          finalValues,
          true,
          "multipart/form-data",
          token
        );
        if (response) {
          const successMessage = response.data.message;
          toast.remove();
          toast.success(successMessage);
          setLoader(false);
          navigate("/subscription", { replace: true });
        }
      } catch (error) {
        errorHandler(error);
        setLoader(false);
      }
    }
  };

  if (currentProfile) {
    initialState.name = currentProfile.name;
    initialState.en = currentProfile.en;
    initialState.sp = currentProfile.sp;
    initialState.image = currentProfile.banner;
    initialState.role = currentProfile.role;
    initialState.active = !currentProfile.archived;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loader && <Loader />}
      {!loader && (
        <Grid
          container
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Grid item xs={6} md={6} lg={6}>
            <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  {mode === "add"
                    ? "Add Subscription Plan"
                    : "Edit Subscription Plan"}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={3} mb={1}>
                <Formik
                  initialValues={initialState}
                  //   validationSchema={
                  //     mode === "add" ? addValidation : editValidation
                  //   }
                  onSubmit={(values) => submitHandler(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <SoftBox>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="space-between">
                            <Grid item xs={5}>
                              <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Title
                                  </SoftTypography>
                                </SoftBox>
                                <FormControl sx={{ width: "100% !important" }}>
                                  <Select
                                    value={values.planTitle}
                                    name="planTitle"
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      paddingLeft: "0px !important",
                                    }}
                                  >
                                    <MenuItem id="planTitle" value="">
                                      None
                                    </MenuItem>
                                    <MenuItem id="planTitle" value="month">
                                      Month
                                    </MenuItem>
                                    <MenuItem id="planTitle" value="year">
                                      Year
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {/* {errors.email && touched.email && errors.email} */}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                            <Grid item xs={5}>
                              <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Duration
                                  </SoftTypography>
                                </SoftBox>
                                <FormControl sx={{ width: "100% !important" }}>
                                  <Select
                                    value={values.planDuration}
                                    name="planDuration"
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      paddingLeft: "0px !important",
                                    }}
                                  >
                                    <MenuItem id="planDuration" value="">
                                      None
                                    </MenuItem>
                                    <MenuItem id="planDuration" value="monthly">
                                      Monthly
                                    </MenuItem>
                                    <MenuItem id="planDuration" value="yearly">
                                      Yearly
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {/* {errors.email && touched.email && errors.email} */}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="space-between">
                            <Grid item xs={5}>
                              <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Subscription Type
                                  </SoftTypography>
                                </SoftBox>
                                <FormControl sx={{ width: "100% !important" }}>
                                  <Select
                                    value={values.subscriptionType}
                                    name="subscriptionType"
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      paddingLeft: "0px !important",
                                    }}
                                  >
                                    <MenuItem id="subscriptionType" value="">
                                      None
                                    </MenuItem>
                                    <MenuItem
                                      id="subscriptionType"
                                      value="free"
                                    >
                                      Free
                                    </MenuItem>
                                    <MenuItem
                                      id="subscriptionType"
                                      value="premium"
                                    >
                                      Premium
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {/* {errors.email && touched.email && errors.email} */}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>

                            <Grid item xs={5}>
                              <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Apply Coupons
                                  </SoftTypography>
                                </SoftBox>
                                <FormControl sx={{ width: "100% !important" }}>
                                  <Select
                                    value={values.couponId}
                                    name="couponId"
                                    onChange={handleChange}
                                    displayEmpty
                                    disabled={
                                      values.subscriptionType === "free"
                                    }
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      paddingLeft: "0px !important",
                                    }}
                                  >
                                    <MenuItem id="couponId" value="">
                                      None
                                    </MenuItem>
                                    {coupons.map((coupon, index) => (
                                      <MenuItem
                                        id="couponId"
                                        value={coupon._id}
                                      >
                                        {coupon.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="error"
                                >
                                  {/* {errors.email && touched.email && errors.email} */}
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack direction="row">
                            <Grid item xs={5}></Grid>
                            <Grid item xs={5}></Grid>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                          >
                            <Grid item xs={6}>
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Description Bullets
                                </SoftTypography>
                              </SoftBox>
                            </Grid>
                            <Grid item xs={6}>
                              <Card
                                sx={{ padding: "5px" }}
                                style={{ backgroundColor: "#f0f1f2" }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item xs={6}>
                                    <SoftButton
                                      type="button"
                                      onClick={() => setValue(0)}
                                      disabled={value === 0}
                                      fullWidth
                                      sx={{ padding: "1px !important" }}
                                    >
                                      English
                                    </SoftButton>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <SoftButton
                                      onClick={() => setValue(1)}
                                      type="button"
                                      disabled={value === 1}
                                      fullWidth
                                    >
                                      Spainesh
                                    </SoftButton>
                                  </Grid>
                                </Stack>
                              </Card>
                            </Grid>
                          </Stack>
                        </Grid>
                        {value === 0 &&
                          descriptionEN.map((_description, index) => (
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item xs={10}>
                                  <SoftBox mb={2}>
                                    <SoftInput
                                      name="artistName"
                                      id="artistName"
                                      value={_description.name}
                                      onBlur={handleBlur}
                                      //   disabled={mode === "edit"}
                                      onChange={(event) =>
                                        handleChangeSubsDescriptionEN(
                                          index,
                                          event
                                        )
                                      }
                                      type="text"
                                      placeholder="Artist Name"
                                    />
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                      color="error"
                                    >
                                      {/* {errors.email && touched.email && errors.email} */}
                                    </SoftTypography>
                                  </SoftBox>
                                </Grid>
                                {index === descriptionEN.length - 1 && (
                                  <Icon
                                    style={{ cursor: "pointer" }}
                                    className="font-bold"
                                    fontSize="30px"
                                    onClick={addDescriptionHandlerEN}
                                  >
                                    add
                                  </Icon>
                                )}
                              </Stack>
                            </Grid>
                          ))}

                        {value === 1 &&
                          descriptionSP.map((_description, index) => (
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item xs={10}>
                                  <SoftBox mb={2}>
                                    <SoftInput
                                      name="artistName"
                                      id="artistName"
                                      value={_description.name}
                                      onBlur={handleBlur}
                                      //   disabled={mode === "edit"}
                                      onChange={(event) =>
                                        handleChangeSubsDescriptionSP(
                                          index,
                                          event
                                        )
                                      }
                                      type="text"
                                      placeholder="Artist Name"
                                    />
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                      color="error"
                                    >
                                      {/* {errors.email && touched.email && errors.email} */}
                                    </SoftTypography>
                                  </SoftBox>
                                </Grid>
                                {index === descriptionSP.length - 1 && (
                                  <Icon
                                    style={{ cursor: "pointer" }}
                                    className="font-bold"
                                    fontSize="30px"
                                    onClick={addDescriptionHandlerSP}
                                  >
                                    add
                                  </Icon>
                                )}
                              </Stack>
                            </Grid>
                          ))}

                        <SoftBox mb={2}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Price
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            name="planPrice"
                            id="planPrice"
                            value={values.planPrice}
                            onBlur={handleBlur}
                            //   disabled={mode === "edit"}
                            onChange={handleChange}
                            type="number"
                            disabled={values.subscriptionType === "free"}
                            placeholder="Plan Price in $"
                          />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {/* {errors.email && touched.email && errors.email} */}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox mt={3} mb={2} lineHeight={1}>
                          <SoftTypography variant="h6">Status</SoftTypography>

                          <Switch
                            id="active"
                            name="active"
                            checked={values.active}
                            onChange={handleChange}
                            value={values.active}
                          />
                        </SoftBox>

                        <SoftBox mt={4} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {mode === "add"
                              ? "Add Subscription Plan"
                              : "Edit Subscription Plan"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </form>
                  )}
                </Formik>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default SubscriptionForm;
